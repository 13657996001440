import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  insertChat: {
    isError:false,
    isSuccess:false,
    isError:false,
    result:[]
  },

}

export const insertChatToDB = createAsyncThunk('insertChat/insertChatToDB', async ({ ip,message,idOfChatBox,mySide}) => {
  return await Axios.post(`${process.env.REACT_APP_NODE_URL}/api/insertChatToDB`, {ip:ip,message:message,idOfChatBox:idOfChatBox,mySide:mySide}).then((response) => response.data
  ).catch(error => console.log(error))
})
// formD.append('ip', visitorChat[0].user_ip)
// formD.append('message', textValue)
// formD.append('idOfChatBox', visitorChat[0].id_chatbox)
// const side = 'left';
// formD.append('mySide', side)


const insertChatSlice = createSlice({
  name: 'insertChat',
  initialState,
  reducers: {
    clearStateInsertChat: (state) => {
      state.insertChat.isError = false;
      state.insertChat.isSuccess = false;
      state.insertChat.isFetching = false;
      state.insertChat.result = [];
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(insertChatToDB.pending, state => {
      // console.log("pending");
      state.insertChat.isFetching = true;
    })
    builder.addCase(insertChatToDB.fulfilled, (state, action) => {
      state.insertChat.data = action.payload.result;
      state.insertChat.isFetching = false;
      state.insertChat.isSuccess = true;
      // state.insertChat.Message = action.payload.Message;
      // console.log("Success");
    })
    builder.addCase(insertChatToDB.rejected, (state, action) => {
      // console.log("Rejected");
      state.insertChat.isError = true;
    })


  }
})
export const { clearStateInsertChat } = insertChatSlice.actions;
export default insertChatSlice.reducer

