import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  chatboxDetail: {},

}

export const saveDetailsToDB = createAsyncThunk('chatboxDetail/saveDetailsToDB', async ({ adminPhNo,folderPath, timestamp, nameOfChatBoxValue, boxContent, logoSelected, regId }) => {
  const formdata = new FormData();
  formdata.append('content', boxContent)
  formdata.append('id', regId)
  formdata.append('logo', logoSelected)
  formdata.append('name', nameOfChatBoxValue)
  formdata.append('created_on', timestamp)
  formdata.append('folderPath', folderPath)
  formdata.append('adminPhNo', adminPhNo)

  return await Axios.post(`${process.env.REACT_APP_NODE_URL}/api/saveChanges`, formdata).then((response) => response.data
  )
})
const saveDetailsSlice = createSlice({
  name: 'chatboxDetail',
  initialState,
  reducers: {
    clearState: (state) => {
      state.chatboxDetail.isError = false;
      state.chatboxDetail.isSuccess = false;
      state.chatboxDetail.isFetching = false;
      // state.chatboxDetail.Message = false;
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(saveDetailsToDB.pending, state => {
      state.chatboxDetail.isFetching = true;
    })
    builder.addCase(saveDetailsToDB.fulfilled, (state, action) => {
      state.chatboxDetail.chatboxDetail = action.payload.result;
      state.chatboxDetail.isFetching = false;
      state.chatboxDetail.isSuccess = true;
      // state.chatboxDetail.Message = action.payload.Message;
    })
    builder.addCase(saveDetailsToDB.rejected, (state, action) => {
      state.chatboxDetail.isFetching = false;
      state.chatboxDetail.isError = true;
    })
    


  }
})
export const { clearState } = saveDetailsSlice.actions;
export default saveDetailsSlice.reducer

