import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from 'axios';


const initialState = {
    domainList: {
        isError: false,
        isSuccess: false,
        isFetching: false,
        listData: []
    }
}
export const getDomainListFromDB = createAsyncThunk('getDomainListSlice/getDomainListFromDB', async () => {
    const config = {

        headers: {
            auth: localStorage.getItem('userIdInLocal')
        }
    }
    const url = `${process.env.REACT_APP_NODE_URL}/api/getDomainNameList`
    return await Axios.get(url, config).then((response) => response.data).catch(error => console.log(error))

})
const getDomainListSlice = createSlice({
    name: 'domainList',
    initialState,
    reducers: {
        clearDomainListState: (state) => {
            state.domainList.isError = false;
            state.domainList.isFetching = false;
            state.domainList.isSuccess = false;
            state.domainList.listData = [];
            return state;
        },
    },
    extraReducers: builder => {
        builder.addCase(getDomainListFromDB.pending, state => {
            state.domainList.isFetching = true;
        })
        builder.addCase(getDomainListFromDB.fulfilled, (state, action) => {

            state.domainList.isSuccess = true;
            state.domainList.isFetching = false;
            state.domainList.listData = action.payload;
        })
        builder.addCase(getDomainListFromDB.rejected, state => {
            state.domainList.isError = true;
        })
    }
})
export const { clearDomainListState } = getDomainListSlice.actions;
export default getDomainListSlice.reducer