import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  registerUser: {
    isError: false,
    isSuccess: false,
    isError: false,
    registerUserData: []
  },

}

export const saveRegData = createAsyncThunk("registration/saveRegData", async ({ fn, ln, email, dn, pass }) => {
  return await Axios.post(`${process.env.REACT_APP_NODE_URL}/api/registerDomain`, {
    first_name: fn,
    last_name: ln,
    registered_email: email,
    domain_name: dn,
    registered_password: pass
  }).then((response) => response.data
  )
})



const RegistrationSlice = createSlice({
  name: 'registerUser',
  initialState,
  reducers: {
    clearStateRegisterU: (state) => {
      state.registerUser.isError = false;
      state.registerUser.isSuccess = false;
      state.registerUser.isFetching = false;
      state.registerUser.registerUserData = [];

      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(saveRegData.pending, state => {
      // console.log("pending");
      state.registerUser.isFetching = true;
    })
    builder.addCase(saveRegData.fulfilled, (state, action) => {
      state.registerUser.isFetching = false;
      state.registerUser.isSuccess = true;
      state.registerUser.registerUserData = action.payload;
      console.log('action', action);
      // console.log("Success");
    })
    builder.addCase(saveRegData.rejected, (state, action) => {
      state.registerUser.isError = true;
      state.registerUser.isFetching = false;

      console.log("registerDomain Rejected", action.error);
    })


  }
})
export const { clearStateRegisterU } = RegistrationSlice.actions;
export default RegistrationSlice.reducer

