import { configureStore, applyMiddleware, compose } from '@reduxjs/toolkit';
import RegisterUserSlice from '../Components/Registration/RegistrationSlice';
import LoginUserSlice from '../Components/Login/LoginSlice';
import ContainerSlice from '../Components/Container/ContainerSlice'
import SaveDetailsSlice from '../Components/Createchatbox/saveDetailsSlice'
import EditDetailsSlice from '../Components/Createchatbox/editDetailsSlice'
import GetVisitorListSlice from '../Components/Conversation/getVisitorListSlice';
import GetVisitorChatSlice from '../Components/Conversation/getVisitorChatSlice';
import InsertChatSlice from '../Components/Conversation/insertChatSlice';
import loadingSlice from '../Components/Loading/LoadingSlice';
import thunk from 'redux-thunk'
import getDomainListSlice from '../Components/AdminPanel/getDomainListSlice'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = configureStore({
  reducer: {
    loadingSlice: loadingSlice,
    registerUser: RegisterUserSlice,
    loginuser: LoginUserSlice,
    chatboxuser: ContainerSlice,
    saveChatboxuser: SaveDetailsSlice,
    editChatboxuser: EditDetailsSlice,
    visitorList: GetVisitorListSlice,
    visitorChat: GetVisitorChatSlice,
    insertChat: InsertChatSlice,
    getDomainList: getDomainListSlice,

  }
}, composeEnhancers(applyMiddleware(thunk)))
