
import './App.css';
import Registration from './Components/Registration/Registration';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { useState } from 'react';
import Alert from './Components/Alert/Alert';
import Navbar from './Components/Navbar/Navbar';
import Home from './Components/Home/Home';
import Conversation from './Components/Conversation/Conversation'
import NotFoundPage from './Components/NotFoundPage/NotFound'
import AdminPanel from './Components/AdminPanel/AdminPanel'
import AdminRegistration from './Components/AdminRegister/AdminRegistration'
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { useEffect } from 'react';

// import { PrivateRoute } from './PrivateRoute/PrivateRoute';
// import Axios from 'axios';


const PrivateRoute = ({ children }) => {
  return localStorage.getItem('isAuthenticate') !== "true" ? <Navigate to="/" /> : children;
};
const PublicRoute = ({ children }) => {
  return localStorage.getItem('isAuthenticate') === "true" ? <Navigate to="/home" /> : children;
};
// const AdminRoute = ({ children }) => {
//   // return localStorage.getItem('isAdmin') === "true" ? <Navigate to="/admin" /> : <Navigate to="/home" />;
//   return 1 == 1 ? <Navigate to="/admin" /> : children;
// };

function App() {
  const [callAlert, setCallAlert] = useState('');
  useEffect(() => {
    console.log('app.js called');
  }, [])


  let forCallAlert = (msg) => {
    setCallAlert(msg)
    setTimeout(() => {
      setCallAlert('')
    }, 2000)
  }

  const state = useSelector(state => state)

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<PublicRoute><Registration forCallAlert={forCallAlert} /></PublicRoute>} />
          <Route exact path="/admin" element={localStorage.getItem('isAdmin') == "true" ? <AdminPanel forCallAlert={forCallAlert} /> : <Navigate to="/home" />} />
          <Route exact path="/admin_registration" element={<AdminRegistration forCallAlert={forCallAlert} />} />
          <Route exact path="/home/*" element={<PrivateRoute><Home forCallAlert={forCallAlert} /></PrivateRoute>} />
          <Route exact path="/conversation" element={<PrivateRoute><Conversation forCallAlert={forCallAlert} /></PrivateRoute>} />
          <Route path="/*" element={<NotFoundPage />} />
        </Routes>
      </BrowserRouter>
      <Alert callAlert={callAlert}></Alert>
    </>

  )
}
export default App;
