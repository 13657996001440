import React from 'react'
import './notFoundPage.css'
export default function NotFound() {
    return (
        <div className='notFoundDiv'>
            <main>
                <h1>
                    Opps! Page Not Found
                </h1>
                <h4>
                    Sorry, the page you're looking for doesn't exist.
                </h4>

            </main>

        </div>
    )
}
