import React, { useEffect, useState } from 'react'
import dp from '../images/dp4.png'

import dp2 from '../images/DP2.webp'
import dp3 from '../images/dp3.webp'
import './conversation.css'
import NavBar from '../Navbar/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { getVisitorListFromDB, clearStateVList } from "./getVisitorListSlice";
import { getVisitorChatFromDB, clearStateVChat } from "./getVisitorChatSlice";
import { insertChatToDB, clearStateInsertChat } from "./insertChatSlice";
import io from 'socket.io-client';
import { useRef } from 'react'
import axios from 'axios'

const socket = io(process.env.REACT_APP_NODE_URL, { path: '/api/socket.io' });

export default function Conversation(props) {
  const [visitorChat, setVisitorChat] = useState([])
  const [currentVisitor, setCurrentVisitor] = useState([])
  const [visitorList, setVisitorList] = useState([])
  // state are comming null in socket.io, therefore used useRef to store data
  const refForUpdate = useRef()

  const messagesContainer = document.getElementById('messagesContainer')

  const visitorListState = useSelector(state => state.visitorList.visitorList)
  const visitorChatState = useSelector(state => state.visitorChat.visitorChat)
  const insertChatState = useSelector(state => state.insertChat.insertChat)
  const dispatch = useDispatch();


  useEffect(() => {
    const token = localStorage.getItem('userIdInLocal')
    const info = { 'token': token }
    socket.emit('connectionAdmin', info)
  }, [])

  useEffect(() => {
    if (currentVisitor) {
      socket.on('receive', onReceiveHandle)
    }
    return () => {
      socket.off('receive', onReceiveHandle);
    };
  }, [socket])

  function called() {
    console.log('visitorListState', visitorListState);
    console.log('currentVisitor', currentVisitor);
    console.log('visitorList', visitorList);
  }

  function scrollToBottom() {
    const scrollingElement = document.getElementById('messagesContainer');
    scrollingElement.style.scrollBehavior = 'smooth'
    scrollingElement.scrollTop = scrollingElement.scrollHeight;
  }
  function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16;//random number between 0 and 16
      if (d > 0) {//Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {//Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
  }
  function onReceiveHandle(msgObj) {
    console.log('message will display')
    console.log('msgObj', msgObj)
    let arr = [msgObj]
    if (refForUpdate.current.cVisitor.chatbox_id != parseInt(msgObj.id_chatbox) || refForUpdate.current.cVisitor.ip_address != msgObj.ip) {
      props.forCallAlert('You have new Message');
      const register_id = localStorage.getItem('userIdInLocal')
      dispatch(getVisitorListFromDB({ register_id }))
    }
    if (refForUpdate.current != null && refForUpdate.current?.cVisitor.chatbox_id == msgObj.id_chatbox && refForUpdate.current?.cVisitor.ip_address == msgObj.ip) {
      if (refForUpdate.current.vChat.length !== 0) {
        setVisitorChat(visitorChat => [...visitorChat, ...arr])
        let visitor_id = refForUpdate.current.cVisitor.id
        let chatbox_id = refForUpdate.current.cVisitor.chatbox_id
        axios.post(`${process.env.REACT_APP_NODE_URL}/api/readStatusSeen`, { visitor_id, chatbox_id })
      }
    }
  }
  useEffect(() => {
    if (currentVisitor && visitorChat) {
      console.log('currentVisitor', currentVisitor);
      refForUpdate.current = {
        cVisitor: currentVisitor,
        vChat: visitorChat
      }
      scrollToBottom()
    } else {
      refForUpdate.current = null
      // document.getElementById('messageContainer').
    }

  }, [visitorChat, currentVisitor])

  function clickToResponse() {
    // if conversation not selected it not reply
    const textValue = document.getElementById('textAreaMessage').value
    if (visitorChat.length === 0) {
      return props.forCallAlert('Please select any of conversation');
    }
    let msgObj = {
      message: textValue,
      side: "left",
    }
    let arr = [msgObj]


    if (textValue.length >= 1) {
      // inputed message inserted in to DOM
      setVisitorChat(visitorChat => [...visitorChat, ...arr])
      messagesContainer.scrollTop += 500;
      const ip = visitorChat[0].user_ip
      const message = textValue
      const idOfChatBox = visitorChat[0].id_chatbox
      const mySide = 'left'
      // dispatch(insertChatToDB({ ip, message, idOfChatBox, mySide }))

      const sendObj = {
        'ip': ip,
        'message': message,
        'idOfChatBox': idOfChatBox,
        'mySide': mySide,
      }
      socket.emit('sendMsg', sendObj)


      document.getElementById('textAreaMessage').value = ''
    } else {
      props.forCallAlert('Please fill the above textarea');
    }
    // messagesContainer.scrollTop += 1500;
  }
  // select-unsaigned, signed, all, open, more,chat
  function selectConversationType(e) {
    const allChildElement = document.getElementById('ulList').childNodes
    allChildElement.forEach(element => {
      element.style.backgroundColor = ''
    });
    e.currentTarget.style.backgroundColor = 'rgb(239 232 232)'
  }
  useEffect(() => {
    const register_id = localStorage.getItem('userIdInLocal')
    dispatch(getVisitorListFromDB({ register_id }))
    return () => {
      dispatch(clearStateVList())
      dispatch(clearStateVChat())
      setVisitorChat([])
      setCurrentVisitor([])
      setSelectedConversation('')
      refForUpdate.current = null
    }
  }, [])


  useEffect(() => {
    if (visitorListState.isSuccess && visitorListState.data?.length > 0) {
      setVisitorList(visitorListState.data)
      dispatch(clearStateVList())
    }
  }, [visitorListState.isSuccess])

  const [selectedConversation, setSelectedConversation] = useState('')


  async function showConversation(e, event) {

    setSelectedConversation('')
    // for not showing prevevious element
    const elements = document.getElementsByClassName("externalDiv");
    while (elements.length > 0) elements[0].remove();

    setCurrentVisitor(e)

    const allChildElement = document.getElementById('removeBg').childNodes
    allChildElement.forEach(element => {
      element.classList.remove('selectedVisitor')
    });
    event.currentTarget.classList.add('selectedVisitor')
    const id = e.id;
    const chatbox_id = e.chatbox_id
    setSelectedConversation({ id, chatbox_id })
    dispatch(getVisitorChatFromDB({ id, chatbox_id }))
    // setVisitorChat(data)
    messagesContainer.scrollTop += 1500;
    // messagesContainer.scrollTo(0,messagesContainer.scrollHeight)
  }
  useEffect(() => {
    if (visitorChatState.isSuccess) {
      setVisitorChat(visitorChatState.chat)
    }
  }, [visitorChatState.isSuccess, visitorChatState.isFetching])

  function showAsideBar() {
    const sidebar = document.querySelector('#sidebar')
    if (sidebar.style.left == '-300px') {
      sidebar.style.left = '0px'
    } else {
      sidebar.style.left = '-300px'
    }
  }
  function getPendingSeenMsg(data) {
    let count = 0;
    data.forEach((a) => {
      if (a.read_status === 0) {
        count++
      }
    })
    if (count > 0) {
      return <span className='newMessage'>{count}</span>
    }
  }
  useEffect(() => {
    const register_id = localStorage.getItem('userIdInLocal')
    dispatch(getVisitorListFromDB({ register_id }))
  }, [visitorChat])

  return (
    <>
      <NavBar></NavBar>
      {/* aside */}
      <aside style={{ height: 'calc(100vh - 56px)' }} id='sidebar' className="sidebar ">
        <ul className='list-group'>
          <li className=''>
            {/* <button onClick={called}>called</button> */}
            <ul className='list-group p-3'>
              <div className='mb-2 d-flex   justify-content-between'>
                <div className=' d-flex justify-content-center align-items-center'>
                  <div id='asidebar' onClick={showAsideBar} className='pe-3 d-lg-none asidebarclick'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                      <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                    </svg>
                  </div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="me-2 bi bi-chevron-double-left" viewBox="0 0 16 16">
                    <path className='my-color' fillRule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    <path className='my-color' fillRule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                  </svg>
                  <div style={{ fontSize: '18px', fontWeight: '600' }}>
                    Inbox
                  </div>
                </div>
                <div className='d-flex my-1 justify-content-between'>
                  <div className=' d-flex justify-content-center align-items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                      <path className='my-color' d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className='mb-4' ><small style={{ fontSize: '', fontWeight: '500' }} className='my-color me-2'>Outside of working hours</small>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                  <path className='my-color' d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                </svg>
              </div>
              <div className='d-flex justify-content-between' style={{ fontSize: '', fontWeight: '500' }}>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className=" bi bi-dot" viewBox="0 0 16 16">
                    <path className='my-color shadow-lg' d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                  </svg>
                  You're available
                </div>
                <div className="p-0 m-0 form-check form-switch">
                  <input style={{
                    height: '25px',
                    borderRadius: '5px',
                    width: '48px',
                    backgroundColor: '#7299b6'
                  }} className="m-0 form-check-input border-0" type="checkbox" id="flexSwitchCheckChecked" defaultChecked />
                </div>
              </div>
            </ul>
          </li>
          <li>

            <ul className='list-group' style={{ cursor: 'pointer' }} id='ulList'>
              <li onClick={(e) => { selectConversationType(e) }} className='p-3 d-flex justify-content-between'>
                <h6 style={{ fontWeight: '400' }}>Unasigned</h6>
                <h6>3</h6>
              </li>
              <li onClick={(e) => { selectConversationType(e) }} className='p-3 d-flex justify-content-between'>
                <h6 style={{ fontWeight: '400' }}>Assigned to me</h6>
                <h6>0</h6>
              </li>
              <li onClick={(e) => { selectConversationType(e) }} style={{ backgroundColor: 'rgb(239 232 232)' }} className=' p-3 d-flex justify-content-between'>
                <h6 style={{ fontWeight: '500' }}>All open</h6>
                <h6>3</h6>
              </li>
              <li onClick={(e) => { selectConversationType(e) }} className='p-3 d-flex justify-content-between'>
                <h6 style={{ fontWeight: '400' }}>Chat</h6>
                <h6>3</h6>
              </li>

              <li onClick={(e) => { selectConversationType(e) }} className='p-3 d-flex justify-content-between'>
                <h6 style={{ fontWeight: '400' }}>More</h6>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-right" viewBox="0 0 16 16">
                  <path className='my-color' fillRule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </li>
            </ul>
          </li>
          <li className=''>
            <ul className='w-100 list-group position-absolute bottom-0' >
              <li className=''>
                <div className='m-3'>
                  <div className="dropdown">
                    <button className="w-100 btn bg-my-light  dropdown-toggle" type="button" id="dropdownMenu2" data-bs-toggle="dropdown" aria-expanded="false">
                      Dropdown
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <li><button className="dropdown-item" type="button">Action</button></li>
                      <li><button className="dropdown-item" type="button">Another action</button></li>
                      <li><button className="dropdown-item" type="button">Something else here</button></li>
                    </ul>
                  </div>
                </div>
              </li>
              <li>
                <div className='m-3'>
                  <div className='d-flex justify-content-start align-items-center'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-3 bi bi-sliders" viewBox="0 0 16 16">
                      <path className='my-color' fillRule="evenodd" d="M11.5 2a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM9.05 3a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0V3h9.05zM4.5 7a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zM2.05 8a2.5 2.5 0 0 1 4.9 0H16v1H6.95a2.5 2.5 0 0 1-4.9 0H0V8h2.05zm9.45 4a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3zm-2.45 1a2.5 2.5 0 0 1 4.9 0H16v1h-2.05a2.5 2.5 0 0 1-4.9 0H0v-1h9.05z" />
                    </svg>
                    <div>
                      Inbox Settings
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </aside>
      {/* main */}

      <main style={{ height: 'calc(100vh - 56px)' }} className='main'>
        <header>
          <div className='m-0 p-0 row'>
            <div className='asideContains col-md-5 col-lg-3 m-0 p-3 '>
              <div className=' d-flex align-items-center p-0 m-0 '>
                <div id='asidebar' onClick={showAsideBar} className='pe-3 d-lg-none asidebarclick'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-list" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
                  </svg>
                </div>
                <div className='px-2'>
                  <div className="p-0 form-check">
                    <input style={{ height: '24px', width: '24px' }} className="  m-0 form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                  </div>
                </div>
                <div className=''>
                  <div className="px-2 btn-group">
                    <button style={{
                      fontSize: '16px',
                      fontWeight: '600'
                    }} className="my-color border-0 bg-white btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Open
                    </button>
                    <ul className="dropdown-menu">
                      ...
                    </ul>
                  </div>
                  <div className="pe-2 btn-group">
                    <button style={{
                      fontSize: '16px',
                      fontWeight: '600'
                    }} className="my-color border-0 bg-white btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Newest
                    </button>
                    <ul className="dropdown-menu">
                      ...
                    </ul>
                  </div>
                </div>

              </div>
            </div>
            <div className='my-border-left my-border-top col-md-7 col-lg-9 p-3 m-0'>
              <nav style={{ minWidth: '165px' }} className='p-0 navbar navbar-expand-sm'>
                <div >
                  <div className='d-flex'>
                    <div className='px-2'>
                      <img style={{ height: '30px', width: '30px', borderRadius: '50%' }} src={currentVisitor ? currentVisitor.visitor_logo ? currentVisitor.visitor_logo : dp3 : dp3} alt="" srcSet="" />
                    </div>
                    <div className='pe-2'>
                      <small className='my-color' style={{ fontWeight: '500' }}>Owner</small>
                      <div className="">
                        <button style={{
                          fontSize: '16px',
                          fontWeight: '600'
                        }} className="p-0 my-color border-0 bg-white btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          No owner
                        </button>
                        <ul className="dropdown-menu">
                          ...
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <button className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-chevron-double-down" viewBox="0 0 16 16">
                    <path fillRule="evenodd" d="M1.646 6.646a.5.5 0 0 1 .708 0L8 12.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                    <path fillRule="evenodd" d="M1.646 2.646a.5.5 0 0 1 .708 0L8 8.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" />
                  </svg>
                </button>
                <div className="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">
                  <ul className="navbar-nav flex-row justify-content-center align-items-center">
                    <li className="nav-item">
                      <div className="bg-light my-border rounded btn-group " role="group" aria-label="Button group with nested dropdown">
                        <button style={{ width: '163px' }} type="button" className="  btn btn-sm">
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" fill="currentColor" className="bi bi-check" viewBox="0 0 16 16">
                            <path d="M10.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.267.267 0 0 1 .02-.022z" />
                          </svg>

                          Close conversation
                        </button>
                        <div className="btn-group dropdown" role="group">
                          <button id="btnGroupDrop1" type="button" className="btn my-border-left dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            <small> More</small>
                          </button>
                          {/* <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                            <li><a className="dropdown-item" href="#">Dropdown link</a></li>
                            <li><a className="dropdown-item" href="#">Dropdown link</a></li>
                          </ul> */}
                        </div>
                      </div>

                    </li>
                    <li className="nav-item">
                      <div className="bg-light my-border mx-2 rounded btn-group   " role="group" aria-label="Button group with nested dropdown">
                        <button type="button" className="btn ">
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-info-circle-fill" viewBox="0 0 16 16">
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z" />
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>

        </header>
        <section style={{ height: 'calc(100% - 80px)' }} className=' m-0 row'>
          <div className='overflowContainer my-border-top  p-0   col-md-3 '>
            <div id='removeBg' className='visitorLi bg-light' >
              {
                visitorList && visitorList?.length > 0 ? visitorList.map(e => {
                  return <li key={e.id} onClick={(event) => { showConversation(e, event) }} className='col-3'>
                    <main className='my-color my-border-bottom d-flex align-items-center justify-content-between'>
                      <div className='d-flex align-items-center'>
                        <div className='pe-2'>
                          <img style={{ height: '30px', width: '30px', borderRadius: '50%' }} className='p-0 m-0' src={e.visitor_logo === null ? dp3 : e.visitor_logo} alt="" srcSet="" />
                        </div>
                        <div className='px-1'>
                          <h6 className='m-0'>{e.visitor_name === null ? 'Unknown Visitor' : e.visitor_name}</h6>
                          <div></div>
                        </div>
                      </div>
                      {
                        e.msg_read_status && e.msg_read_status.length > 0 && getPendingSeenMsg(e.msg_read_status)

                      }
                    </main>
                  </li>
                })
                  : <div style={{ minHeight: '95px' }} className='d-flex justify-content-center my-border-bottom  align-items-center h-100' >
                    <p className='my-color m-0' style={{ fontWeight: '500' }}>No conversation</p>
                  </div>
              }
            </div>
          </div>
          <div className='my-border h-100 bg-light p-0 m-0  col-md-6'>
            <div className='h-100 position-relative p-0 m-0' >
              {/* container that have chat */}
              <div id='messagesContainer' style={{ height: 'calc(100% - 198.5px)', overflowY: 'auto' }} className={`${visitorChat?.length > 0 && 'boxShadowAbove'} messagesContainer d-flex flex-column p-0 m-0`}>
                {
                  visitorChatState.isSuccess ? visitorChat.map((d, index) => {
                    if (d.side === 'right') {
                      return <div key={index} className='leftSide messageDiv p-3 d-flex justify-content-start'>
                        <div style={{ direction: 'ltr' }} className=' pe-2 m-0'>
                          <img style={{ height: '40px', width: '40px', borderRadius: '50%' }} className='p-0 m-0' src={currentVisitor ? currentVisitor.visitor_logo ? currentVisitor.visitor_logo : dp3 : dp3} alt="" srcSet="" />
                        </div>
                        <div className='rounded border m-0 p-0'>
                          <div style={{ borderRadius: '0.25rem 0.25rem 0rem 0rem ', padding: '10px 17px', backgroundColor: '#7299b6', fontWeight: '600' }} className='text-white'>
                            <svg style={{ marginRight: '10px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-right-text-fill" viewBox="0 0 16 16">
                              <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z" />
                            </svg>
                            {currentVisitor ? currentVisitor.visitor_name ? currentVisitor.visitor_name : 'Unknown visitor' : 'Unknown visitor'}
                          </div>
                          <div style={{ fontWeight: '400', padding: '10px 17px' }} className=' '>{d.message}</div>
                        </div>
                      </div>
                    }
                    if (d.side === 'left') {
                      return <div key={index} className='rightSide messageDiv d-flex p-3 justify-content-end'>
                        <div className='rounded border  m-0 p-0'>
                          <div style={{ borderRadius: '0.25rem 0.25rem 0rem 0rem ', padding: '10px 17px', backgroundColor: '#7299b6', fontWeight: '600' }} className='text-white'>
                            <svg style={{ marginRight: '10px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-right-text-fill" viewBox="0 0 16 16">
                              <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z" />
                            </svg>
                            Automated</div>
                          <div style={{ fontWeight: '400', padding: '10px 17px' }} className=' '>{d.message}</div>
                        </div>
                        <div style={{ direction: 'rtl' }} className=' ps-2 m-0'>
                          <img style={{ height: '40px', width: '40x', borderRadius: '50%' }} className='p-0 m-0' src={dp2} alt="" srcSet="" />
                        </div>
                      </div>
                    }
                  }) : <p className='my-color d-flex justify-content-center align-items-center h-100' style={{ fontWeight: '500' }}>Please select conversation</p>

                }
              </div>
              {/* textarea */}
              <div style={{ height: 'vh' }} className='  bottom-0 end-0 start-0 position-absolute bg-white p-0 '>
                <header className='my-border-top my-border-bottom'>
                  <div className=' '>
                    <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button style={{ color: '#495057', fontWeight: '500' }} className=" nav-link my active" id="home-tab" data-bs-toggle="tab" data-bs-target="#chat-conversation"
                          type="button" role="tab" aria-controls="home" aria-selected="true">
                          <svg style={{ marginRight: '10px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chat-right-text-fill" viewBox="0 0 16 16">
                            <path d="M16 2a2 2 0 0 0-2-2H2a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h9.586a1 1 0 0 1 .707.293l2.853 2.853a.5.5 0 0 0 .854-.353V2zM3.5 3h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1 0-1zm0 2.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1z" />
                          </svg>
                          Chat</button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button style={{ color: '#495057', fontWeight: '500' }} className="nav-link my" id="profile-tab" data-bs-toggle="tab" data-bs-target="#comment-conversation"
                          type="button" role="tab" aria-controls="profile" aria-selected="false">Comment</button>
                      </li>
                    </ul>
                  </div>

                </header>
                <main>
                  <div style={{ height: '100px' }} className="tab-content pt-2" id="borderedTabJustifiedContent">
                    <div className="h-100 tab-pane fade active show" id="chat-conversation" role="tabpanel" aria-labelledby="home-tab">
                      <textarea id='textAreaMessage' placeholder='Write a message' style={{ resize: 'none', outline: '0' }} className="border-0 h-100 w-100 px-3"></textarea>
                    </div>
                    <div className="tab-pane fade chat-conversation " id="comment-conversation" role="tabpanel" aria-labelledby="profile-tab">
                      comment
                    </div>
                  </div>
                </main>
                <footer className='p-2 border-top position-absulate bottom-0 end-0 start-0 bg-light'>
                  <div className='d-flex justify-content-between'>
                    <div className='d-flex justify-content-center align-items-center'>

                      <div className='mx-2' style={{ fontWeight: '500', fontSize: '25px' }}>A</div>
                      <div style={{ borderRight: '1px solid #dee2e6' }}>

                        <svg style={{ margin: "0px 10px" }} sxmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-link-45deg" viewBox="0 0 16 16">
                          <path d="M4.715 6.542 3.343 7.914a3 3 0 1 0 4.243 4.243l1.828-1.829A3 3 0 0 0 8.586 5.5L8 6.086a1.002 1.002 0 0 0-.154.199 2 2 0 0 1 .861 3.337L6.88 11.45a2 2 0 1 1-2.83-2.83l.793-.792a4.018 4.018 0 0 1-.128-1.287z" />
                          <path d="M6.586 4.672A3 3 0 0 0 7.414 9.5l.775-.776a2 2 0 0 1-.896-3.346L9.12 3.55a2 2 0 1 1 2.83 2.83l-.793.792c.112.42.155.855.128 1.287l1.372-1.372a3 3 0 1 0-4.243-4.243L6.586 4.672z" />
                        </svg>
                      </div>

                      <svg style={{ margin: '0px 10px', }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
                        <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                      </svg>

                      <div className="mx-2 btn-group">
                        <button style={{
                          fontSize: '16px',
                          fontWeight: '600'
                        }} className="bg-light border-0  btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                          Newest
                        </button>
                        <ul className="dropdown-menu">
                          ...
                        </ul>
                      </div>
                    </div>
                    <div style={{ color: 'rgb(222, 226, 230)' }} className="bg-light rounded btn-group my-border" role="group" aria-label="Button group with nested dropdown">
                      <button onClick={clickToResponse} type="button" className=" -right btn btn-sm">
                        Send</button>
                      <div className="btn-group my-border-left" role="group">
                        <button id="btnGroupDrop1" type="button" className="btn btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="btnGroupDrop1">
                          <li><a className="dropdown-item" href="#">Dropdown link</a></li>
                          <li><a className="dropdown-item" href="#">Dropdown link</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </footer>

              </div>
            </div>
          </div>
          <div className='my-border-top visitorProfile p-0  col-md-3'>
            <div className=''>
              <div className='p-4 my-border-bottom'>
                <img style={{ height: '45px', width: '45px', borderRadius: '50%' }} className='col-2 p-0 m-0' src={currentVisitor ? currentVisitor.visitor_logo ? currentVisitor.visitor_logo : dp3 : dp3} alt="" srcSet="" />
                <div className=''>
                  {
                    currentVisitor && currentVisitor.length !== 0 ? <>
                      <p className='py-2 m-0 my-color' style={{ fontWeight: '500' }}>Name: {currentVisitor ? currentVisitor.visitor_name ? currentVisitor.visitor_name : 'Unknown visitor' : 'Unknown visitor'}</p>
                      <p className='pb-2 m-0 my-color' style={{ fontWeight: '500' }}>Phone number: {currentVisitor && currentVisitor.phone_number}</p>
                      <p className='pb-2 m-0 my-color' style={{ fontWeight: '500' }}>Email: {currentVisitor && currentVisitor.email}</p>
                    </> : <p className='py-3 m-0' style={{ fontWeight: '500' }}>Select conversation</p>

                  }
                  <button className='bg-my-dark text-white btn  btn-sm'>Associate visitor</button>
                </div>
              </div>
              {

                (currentVisitor.chatbox_name || currentVisitor.logo) && <div className='my-border-bottom p-4'>
                  <p className='mb-2 my-color' style={{ fontWeight: '500' }}>Chatbox details</p>
                  <div className='d-flex bg-light border flex-wrap rounded p-2'>
                    {
                      currentVisitor.logo && < img style={{ height: '50px', width: '50px', borderRadius: '50%' }} className='mx-2 col-2 p-0 m-0' src={`${process.env.REACT_APP_NODE_URL}/api${currentVisitor.logo}`} alt="" srcSet="" />
                    }
                    {
                      currentVisitor.chatbox_name && <p className='m-0 d-flex align-items-center mx-2 my-color' style={{ fontWeight: '500' }}>{currentVisitor.chatbox_name}</p>
                    }
                  </div>
                </div>
              }
              <div className='my-border-bottom p-4'>
                <p className='mb-4'>Create a ticket from this conversation</p>
                <button className='  btn btn-sm btn-light'>Create a ticket</button>
              </div>
            </div>
          </div>
        </section>


      </main>

    </>
  )
}
