import React from "react";
import axios from "axios";
// import logo from '../images/chatbox_icon.png'
import logo from '../images/chatbox_icon.png'
import { useState } from "react";

import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
import { saveRegData, clearStateRegisterU } from "./RegistrationSlice";
import { getLoginUserDataAsync, clearStateLogin } from "../Login/LoginSlice";
import { useEffect } from "react";
function Registration(props) {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const Loginuser = useSelector((state) => state.loginuser.Loginuser);
  const registerUser = useSelector(state => state.registerUser.registerUser)

  // useEffect(() => {
  //   console.log("reg.....");
  //   if (isSuccess) {
  //     props.forCallAlert(Message);
  //     dispatch(clearState());
  //   }
  // }, [isSuccess])

  // //console.log(loginData, "loginData");

  // const [registrationDetails, setRegistrationDetails] = useState({
  //   firstName: "",
  //   lastName: "",
  //   emailId: "",
  //   domainName: "",
  //   password: "",
  // });

  // function detailsOnChange(e) {
  //   const value = e.target.value;
  //   const name = e.target.name;
  //   setRegistrationDetails({ ...registrationDetails, [name]: value });
  // }

  // function AfterRegFunction() {
  //   document.getElementById("logInBtn").click();
  //   props.forCallAlert(
  //     "Registration successfully..! Now you can logIn to Chatbox"
  //   );
  // }

  // const RegisterClickHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(saveRegData(registrationDetails));
  //   AfterRegFunction();
  // };


  // const [loginDetails, setLoginDetails] = useState({
  //   emailId: "",
  //   password: "",
  // });

  // function logInChange(e) {
  //   const value = e.target.value;
  //   const name = e.target.name;
  //   setLoginDetails({ ...loginDetails, [name]: value });
  //   console.log(loginDetails)
  // }

  // const LogInHandler = (e) => {
  //   e.preventDefault();
  //   dispatch(getRegisterId(loginDetails))
  //   dispatch(getLoginUserDataAsync(loginDetails));
  // };


  const [registrationDetails, setRegistrationDetails] = useState({
    firstName: '',
    lastName: '',
    emailId: '',
    domainName: '',
    password: ''
  })
  function detailsOnChange(e) {
    const value = e.target.value
    const name = e.target.name
    setRegistrationDetails({ ...registrationDetails, [name]: value })
  }

  const [loginDetails, setLoginDetails] = useState({
    emailId: '',
    password: ''
  })
  function logInChange(e) {
    const value = e.target.value
    const name = e.target.name
    setLoginDetails({ ...loginDetails, [name]: value })
  }

  function RegisterClickHandler(event) {
    event.preventDefault();
    const fn = registrationDetails.firstName
    const ln = registrationDetails.lastName
    const email = registrationDetails.emailId
    const dn = registrationDetails.domainName
    const pass = registrationDetails.password

    if (fn && ln && email && dn && pass) {
      if (fn.length > 2 && ln.length > 2 && email.length > 2 && dn.length > 2 && pass.length > 2) {
        dispatch(saveRegData({ fn, ln, email, dn, pass }));
      } else {
        props.forCallAlert('All field must have minimum 3 characters')
      }
    } else {
      props.forCallAlert('Please fill all the field')
    }
  }
  useEffect(() => {
    console.log('registerUser', registerUser);

    if (registerUser.isSuccess) {
      if (registerUser.registerUserData.message == 'success') {
        document.getElementById('logInBtn').click()
        props.forCallAlert('Registration successfully..! Now you can logIn to Chatbox')
        setRegistrationDetails({
          firstName: '',
          lastName: '',
          emailId: '',
          domainName: '',
          password: ''
        })
        dispatch(clearStateRegisterU())
        
      } else if (registerUser.registerUserData.message == 'already exist') {
        props.forCallAlert('Email already exist')
      } else {
        props.forCallAlert('Server is down, Please try again later!')
      }
    }
  }, [registerUser])
  function LogInHandler(event) {
    event.preventDefault();
    const email = loginDetails.emailId
    const pass = loginDetails.password
    if (email.length > 2 && pass.length > 2) {
      dispatch(getLoginUserDataAsync(loginDetails));
    } else {
      props.forCallAlert('please fill all the field')
    }
  }
  useEffect(() => {
    const isAdmin = localStorage.getItem('isAdmin')
    console.log('isAdmin', isAdmin)
    if (isAdmin == 'true') {
      Navigate('/admin')
    }

  }, [])
  useEffect(() => {
    if (Loginuser.isError) {
      console.log('Loginuser', Loginuser);
      props.forCallAlert('Server is down, Please try again later!')
    }
  }, [Loginuser])
  useEffect(() => {
    if (Loginuser.Loginuser?.length != []) {
      console.log('Loginuser', Loginuser);
    }
    if (Loginuser.isSuccess === true) {
      if (Loginuser.loginData.message === true) {
        props.forCallAlert('Welcome to Chatbox')
        document.getElementById('logInBtn').click()
        console.log('Loginuser.loginData', Loginuser.loginData.result.tokenIs)
        localStorage.setItem('userIdInLocal', Loginuser.loginData.result.tokenIs)
        setLoginDetails({
          emailId: '',
          password: ''
        })
        const userData = Loginuser?.loginData.result.userData
        console.log('Loginuser?.loginData.result.userData.role',);
        localStorage.setItem('userData', JSON.stringify(userData))
        if (Loginuser?.loginData.result.userData.role == 1) {
          localStorage.setItem('isAdmin', true)
          Navigate('/admin')
        } else {
          localStorage.setItem('isAuthenticate', true)
        }
      } else if (Loginuser.loginData.message === 'error') {
        props.forCallAlert('Server error')
      }
      else if (Loginuser.loginData.message === 'empty') {
        props.forCallAlert('User not exist')
      } else if (Loginuser.loginData.message === 'wrong') {
        props.forCallAlert('Password is not matching')
      } else if (Loginuser.loginData.message === 'deactivate') {
        props.forCallAlert('Your account has been disabled')
      }
      dispatch(clearStateLogin())
    }
  }, [Loginuser])



  return (
    <main>
      <div className='container' >

        <section className='section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4'>
          <div className='container'>

            <div className='row justify-content-center'>
              <div className='d-flex justify-content-center align-items-center m-2 fs-4'>
                <img className='mx-2' style={{ height: '30px', width: '30px' }} src={logo} alt="Logo" />
                <strong>Chatbox</strong>
              </div>
              <div className="text-center col-md-6 col-lg-4 bg-white shadow-lg bg-white rounded px-4 py-5   ">
                {/* register and logIn */}
                <ul className=" nav nav-pills  nav-fill mb-4" id="pills-tab" role="tablist">
                  <li className=" bg-light nav-item col-4 me-2" role="presentation">
                    <button className="nav-link active" id="registerBtn" data-bs-toggle="pill" data-bs-target="#pills-home"
                      type="button" role="tab" aria-controls="pills-home" aria-selected="true">Register</button>
                  </li>
                  <li className="bg-light nav-item col-4 ms-2" role="presentation">
                    <button className="nav-link" id="logInBtn" data-bs-toggle="pill" data-bs-target="#pills-profile"
                      type="button" role="tab" aria-controls="pills-profile" aria-selected="false">Login</button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {/* Register */}
                  <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                    <h5 className="card-title text-center pb-0 fs-4">Register to Chatbox</h5>
                    <form >
                      <div className='row'>
                        <div className="col-md-6 py-2 form-floating">
                          <input autoComplete='firstname' className="form-control" onChange={detailsOnChange} name='firstName' id='firstName' placeholder='firstname' required />
                          <label className='p-4' htmlFor="floatingInput">First Name</label>
                        </div>
                        <div className="col-md-6 py-2 form-floating">
                          <input autoComplete='lastname' className="form-control" onChange={detailsOnChange} name='lastName' id='lastName' placeholder='lastname' required />
                          <label className='p-4' htmlFor="floatingInput">Last Name</label>
                        </div>
                      </div>
                      <div className="py-2 form-floating">
                        <input autoComplete='domainname' className="form-control" onChange={detailsOnChange} name='domainName' id='domainName' placeholder='domainname' required />
                        <label htmlFor="floatingInput">Domain name</label>
                      </div>
                      <div className="py-2 form-floating">
                        <input type={'email'} autoComplete='email' className="form-control" onChange={detailsOnChange} name='emailId' id='emailId' placeholder='email' required />
                        <label htmlFor="floatingInput">Email</label>
                      </div>
                      <div className="py-2 form-floating">
                        <input type={'password'} autoComplete='password' className="form-control" name='password' id='password' onChange={detailsOnChange} placeholder='password' required />
                        <label htmlFor="floatingInput">Password</label>
                      </div>
                      <div className="py-2 checkbox mb-3">
                        <label>
                          <input type="checkbox" value="remember-me" /> Remember me
                        </label>
                      </div>
                      <button onClick={RegisterClickHandler} className="w-100 btn btn-lg btn-primary" type="submit">Register</button>
                    </form>
                    <div className="text-start mt-2">
                      <Link to='/admin_registration'>
                        <a>Admin registration</a>
                      </Link>
                    </div>
                  </div>
                  {/* LogIn */}
                  <div className="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <h5 className="card-title text-center pb-0 fs-4">Login to Chatbox</h5>
                    <form>

                      <div className="py-2 form-floating">
                        <input autoComplete='email_id' type="text" className="form-control" name='emailId' onChange={logInChange} placeholder="name@example.com" required />
                        <label htmlFor="floatingInput">Email</label>
                      </div>
                      <div className="py-2 form-floating">
                        <input autoComplete="current-password" type="password" className="form-control" onChange={logInChange} name='password' placeholder="Password" />
                        <label htmlFor="floatingPassword">Password</label>
                      </div>
                      <div className="py-2 checkbox mb-3">
                        <button onClick={LogInHandler} className="w-100 btn btn-lg btn-primary" type="submit">Login</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </main>
  );
};
export default Registration;
