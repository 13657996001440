import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import logo from '../images/chatbox_icon.png'
import './adminNavbar.css'
import { useState, useEffect } from 'react';
import { useRef } from 'react';

export default function AdminNav(props) {


  return (
    <>
      <nav className="navbarDiv navbar navbar-expand-sm navbar-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img className='mx-2' style={{ height: '24px', width: '24px' }} src={logo} alt="Logo" />
            <strong>Chatbox</strong></Link>
          {/* <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className=" collapse navbar-collapse" id="navbarSupportedContent">
            <ul id='ulOfList' className="navbar-nav">
              <li className="nav-item">
                <Link to="/conversation" className=" nav-link ">
                  Conversations
                </Link>
              </li>
            </ul>

          </div> */}
        </div>
      </nav>
    </>

  )
}

