import React from "react";
import Axios from "axios";
// import logo from '../images/chatbox_icon.png'
import logo from '../images/chatbox_icon.png'
import { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from "react-redux";
// import { saveRegData, clearStateRegisterU } from "./RegistrationSlice";
// import { getLoginUserDataAsync, clearStateLogin } from "../Login/LoginSlice";
import { useEffect } from "react";
export default function AdminRegistration(props) {
    const dispatch = useDispatch();
    const Loginuser = useSelector((state) => state.loginuser.Loginuser);
    const registerUser = useSelector(state => state.registerUser.registerUser)

    const [registrationDetails, setRegistrationDetails] = useState({
        firstName: '',
        lastName: '',
        emailId: '',
        password: '',
        confirmPassword: ''
    })
    function detailsOnChange(e) {
        const value = e.target.value
        const name = e.target.name
        setRegistrationDetails({ ...registrationDetails, [name]: value })
    }

    useEffect(() => {
        console.log('registrationDetails', registrationDetails);
    }, [registrationDetails])
    const [loginDetails, setLoginDetails] = useState({
        emailId: '',
        password: ''
    })


    async function RegisterClickHandler(event) {
        event.preventDefault();

        const fn = registrationDetails.firstName
        const ln = registrationDetails.lastName
        const email = registrationDetails.emailId
        const pass = registrationDetails.password
        const cPass = registrationDetails.confirmPassword

        if (fn && ln && email && pass && cPass) {
            if (fn.length > 2 && ln.length > 2 && email.length > 2 && cPass.length > 2 && pass.length > 2) {
                // dispatch(saveRegData({ fn, ln, email, dn, pass }));

                const fData = new FormData
                fData.append('firstName', fn)
                fData.append('lastName', ln)
                fData.append('emailId', email)
                fData.append('password', pass)
                fData.append('confirmPassword', cPass)
                await Axios.post(`${process.env.REACT_APP_NODE_URL}/api/registerAdmin`, fData).then(async (response) => {
                    const a = await response.data
                    if (a.message == 'success') {
                        console.log('succes')
                        props.forCallAlert('Registration successfully!, Now you can logIn to Chatbox')
                    } else if (a.message == 'already exist') {
                        props.forCallAlert('Email already exist')
                    } else {
                        console.log('error')
                        props.forCallAlert('Something went wrong')
                    }
                })
                console.log('complete **********')
            } else {
                props.forCallAlert('All field must have minimum 3 characters')
            }
        } else {
            props.forCallAlert('Please fill all the field')
        }
    }
    useEffect(() => {
        if (registerUser.isError) {
            props.forCallAlert('Server is down, Please try again later!')
        }
    }, [registerUser])
    useEffect(() => {
        if (registerUser.isSuccess && registerUser.registerUserData== '') {
        props.forCallAlert('Registration successfully..! Now you can logIn to Chatbox')
        setRegistrationDetails({
            firstName: '',
            lastName: '',
            emailId: '',
            domainName: '',
            password: ''
        })
    }
}, [registerUser.isSuccess])

return (
    <main>
        <div className='container' >

            <section className='section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4'>
                <div className='container'>

                    <div className='row justify-content-center'>
                        <div className='d-flex justify-content-center align-items-center m-2 fs-4'>
                            <img className='mx-2' style={{ height: '30px', width: '30px' }} src={logo} alt="Logo" />
                            <strong>Chatbox</strong>
                        </div>
                        <div className="text-center col-md-6 col-lg-4 bg-white shadow-lg bg-white rounded p-4    ">


                            <div className="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                                <h5 className="card-title text-center mb-3 fs-4">Admin Registration</h5>
                                <form >
                                    <div className='row'>
                                        <div className="col-md-6 py-2 form-floating">
                                            <input autoComplete='firstname' className="form-control" onChange={detailsOnChange} name='firstName' id='firstName' placeholder='firstname' required />
                                            <label className='p-4' htmlFor="floatingInput">First Name</label>
                                        </div>
                                        <div className="col-md-6 py-2 form-floating">
                                            <input autoComplete='lastname' className="form-control" onChange={detailsOnChange} name='lastName' id='lastName' placeholder='lastname' required />
                                            <label className='p-4' htmlFor="floatingInput">Last Name</label>
                                        </div>
                                    </div>
                                    <div className="py-2 form-floating">
                                        <input type={'email'} autoComplete='email' className="form-control" onChange={detailsOnChange} name='emailId' id='emailId' placeholder='email' required />
                                        <label htmlFor="floatingInput">Email</label>
                                    </div>
                                    <div className="py-2 form-floating">
                                        <input type={'password'} autoComplete='password' className="form-control" name='password' id='password' onChange={detailsOnChange} placeholder='password' required />
                                        <label htmlFor="floatingInput">Password</label>
                                    </div>
                                    <div className="py-2 form-floating">
                                        <input type={'password'} autoComplete='password' className="form-control" name='confirmPassword' id='confirmPassword' onChange={detailsOnChange} placeholder='Confirm password' required />
                                        <label htmlFor="floatingInput">Confirm password</label>
                                    </div>
                                    <div className="py-2 checkbox mb-1">
                                        <label>
                                            <input type="checkbox" value="remember-me" /> Remember me
                                        </label>
                                    </div>
                                    <button onClick={RegisterClickHandler} className="w-100 btn btn-lg btn-primary" type="submit">Register</button>
                                    <div className="text-start mt-2">
                                        <Link to='/'>
                                            <a>User registration</a>
                                        </Link>
                                    </div>
                                    <div className="text-start">
                                        <p className="m-0">
                                            Already Registered?<Link to='/'>
                                                <a> Click here to login</a>
                                            </Link>
                                        </p>
                                    </div>
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </main>
);
};
