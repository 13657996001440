import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    visitorChat: {
        isError: false,
        isSuccess: false,
        chat: []
    },

}

export const getVisitorChatFromDB = createAsyncThunk('visitorChat/getVisitorChatFromDB', async ({ id, chatbox_id }) => {
    return await Axios.post(`${process.env.REACT_APP_NODE_URL}/api/visitor_chat`, { visitor_id: id, chatbox_id: chatbox_id }).then((response) => response.data
    ).catch(error => console.log(error))
})

const getVisitorListSlice = createSlice({
    name: 'visitorChat',
    initialState,
    reducers: {
        clearStateVChat: (state) => {
            state.visitorChat.isError = false;
            state.visitorChat.isSuccess = false;
            state.visitorChat.isFetching = false;
            state.visitorChat.chat = [];
            return state;
        },
    },
    extraReducers: builder => {
        builder.addCase(getVisitorChatFromDB.pending, state => {
            // console.log("pending");
            state.visitorChat.isFetching = true;
        })
        builder.addCase(getVisitorChatFromDB.fulfilled, (state, action) => {
            state.visitorChat.chat = action.payload.result;
            state.visitorChat.isFetching = false;
            state.visitorChat.isSuccess = true;
            // state.visitorChat.Message = action.payload.Message;
            // console.log("Success");
            
        })
        builder.addCase(getVisitorChatFromDB.rejected, (state, action) => {
            // console.log("Rejected");
            state.visitorChat.isError = true;
        })
    }
})
export const { clearStateVChat } = getVisitorListSlice.actions;
export default getVisitorListSlice.reducer

