import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';


const initialState = {
  chatboxData: {
    isFetching: false,
    isSuccess: false,
    isError: false,
  },
}

export const getDataFromChatboxDetail = createAsyncThunk('chatbotDetails/getDataFromChatboxDetail', async () => {
  const token = localStorage.getItem('userIdInLocal')
  return await Axios.get(`${process.env.REACT_APP_NODE_URL}/api/getChatBoxDetails`, { headers: { "Authorization": token } }).then((response) => response.data
  ).catch(error => console.log(error))
})

const ContainerSlice = createSlice({
  name: 'chatbotDetails',
  initialState,
  extraReducers: builder => {
    builder.addCase(getDataFromChatboxDetail.pending, state => {
      // console.log("pending");
      state.chatboxData.isFetching = true;
    })
    builder.addCase(getDataFromChatboxDetail.fulfilled, (state, action) => {
      state.chatboxData.GetChatboxData = action.payload?.result;
      state.chatboxData.isFetching = false;
      state.chatboxData.isSuccess = true;
      state.chatboxData.Message = action.payload?.Message;
      // console.log("Success");
    })
    builder.addCase(getDataFromChatboxDetail.rejected, (state, action) => {
      //  console.log("Rejected");
      state.chatboxData.isError = true;
    })
  }
})

export default ContainerSlice.reducer
