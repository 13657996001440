import React from 'react';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Chatbox from '../chatbox/box.js'
import Navbar from '../Navbar/Navbar.jsx'
import { setValue, setLoadingValue } from '../Loading/LoadingSlice';
import { useDispatch } from 'react-redux';

import './Stepper.css'


export default function Steppers(props) {
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setLoadingValue(132));
    }, [])


    const navigate = useNavigate();

    function showChatBoxFun() {
        // props.showChatbox(false)
        navigate("/home/createchatbox");
    }
    function backToMainFun() {
        // props.showOrHide(true)
        navigate("/home/");

    }
    const navHome = document.getElementById('nav-home')
    const navProfile = document.getElementById('nav-profile')
    const navContact = document.getElementById('nav-contact')


    function firstHandler(e) {
        e.target.classList.add('notDone')
        document.getElementById('firstStepper').classList.remove('active')
        document.getElementById('secondStepper').classList.remove('active', 'notDone')
        document.getElementById('thirdStepper').classList.remove('active', 'notDone')

        document.getElementById('nav-home').classList.add('active', 'show')
        document.getElementById('nav-profile').classList.remove('active', 'show')
        document.getElementById('nav-contact').classList.remove('active', 'show',)
    }
    function secondHandler(e) {
        e.target.classList.add('notDone')
        document.getElementById('firstStepper').classList.add('active')
        document.getElementById('secondStepper').classList.remove('active')
        document.getElementById('thirdStepper').classList.remove('active', 'notDone')


        document.getElementById('nav-home').classList.remove('active', 'show')
        document.getElementById('nav-profile').classList.add('active', 'show')
        document.getElementById('nav-contact').classList.remove('active', 'show')
    }
    function thirdHandler(e) {
        e.target.classList.add('notDone')
        document.getElementById('firstStepper').classList.add('active')
        document.getElementById('secondStepper').classList.add('active')
        document.getElementById('thirdStepper').classList.remove('active')


        document.getElementById('nav-home').classList.remove('active', 'show')
        document.getElementById('nav-profile').classList.remove('active', 'show')
        document.getElementById('nav-contact').classList.add('active', 'show')

    }



    return (
        <>
            <Navbar></Navbar>
            <div id="div2" style={{ height: 'calc(100vh - 56px)' }} className="d-flex flex-column ">
                <main className=" d-grid container">
                    <div className='cotnainer'>
                        <nav>
                            <div className="nav " id="nav-tab" role="tablist">
                                <ul className="py-3 m-0 d-flex justify-content-center progressbar container">
                                    <li className="notDone" onClick={firstHandler} id='firstStepper' data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="false">
                                        Channel</li>
                                    <li data-bs-toggle="tab" onClick={secondHandler} id='secondStepper' data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">
                                        Chatflow</li>
                                    <li data-bs-toggle="tab" onClick={thirdHandler} data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false" id='thirdStepper'>
                                        Detail</li>
                                </ul>
                            </div>
                        </nav>
                        {/* three container  */}
                        <div className="tab-content container p-0" id="nav-tabContent">
                            {/* first step container  */}
                            <div className="tab-pane fade show active " id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                <div className="m-4 d-flex justify-content-center">
                                    <div className='row  align-items-stretch g-4'>

                                        <div className="col btn btn-light btn-outline-secondary mx-2" style={{ width: '15rem', height: '12rem' }}>
                                            <div className=" flex-column d-flex justify-content-center align-items-center card-body">
                                                <svg style={{ height: 'auto', width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-laptop" viewBox="0 0 16 16">
                                                    <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                                                </svg>
                                                <h4 className="card-title">Website</h4>
                                            </div>
                                        </div>
                                        <div className="col mx-2 btn btn-light btn-outline-secondary" style={{ width: '15rem', height: '12rem' }}>
                                            <div className="  flex-column  d-flex justify-content-center align-items-center card-body">
                                                <svg style={{ height: 'auto', width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-messenger" viewBox="0 0 16 16">
                                                    <path d="M0 7.76C0 3.301 3.493 0 8 0s8 3.301 8 7.76-3.493 7.76-8 7.76c-.81 0-1.586-.107-2.316-.307a.639.639 0 0 0-.427.03l-1.588.702a.64.64 0 0 1-.898-.566l-.044-1.423a.639.639 0 0 0-.215-.456C.956 12.108 0 10.092 0 7.76zm5.546-1.459-2.35 3.728c-.225.358.214.761.551.506l2.525-1.916a.48.48 0 0 1 .578-.002l1.869 1.402a1.2 1.2 0 0 0 1.735-.32l2.35-3.728c.226-.358-.214-.761-.551-.506L9.728 7.381a.48.48 0 0 1-.578.002L7.281 5.98a1.2 1.2 0 0 0-1.735.32z" />
                                                </svg>
                                                <h5 className="card-title">Facebook messenger</h5>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* second step container */}
                            <div className=" tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                {/* second's container  */}
                                <div className="p-0 m-0 row">
                                    {/*2nd first  */}
                                    <div style={{ minWidth: '336px', height: 'calc(100vh - 71px - 56px - 97px)' }} className=" col card border-0 overflow-auto " >
                                        <div>
                                            <h3>Live chat</h3>
                                            <hr />
                                            {/* <div className="mx-3 my-2 card ">
                                                <div className="card-body">
                                                    <h5 className="card-title">Live chat</h5>
                                                    <p className="card-text">With supporting text below as a natural lead-in to
                                                        additional
                                                        content.</p>
                                                    <a href="#" className="btn btn-secondary">Button</a>
                                                </div>
                                            </div> */}
                                            <div className="mx-3 btn-light btn-outline-secondary my-2 card ">
                                                <div className="card-body m-3 flex-raw d-flex justify-content-center align-items-center">
                                                    <svg style={{ height: 'auto', width: '80px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-4 bi bi-chat-right-dots" viewBox="0 0 16 16">
                                                        <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z" />
                                                        <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                                    </svg>
                                                    <div>
                                                        <h5 className="card-title">Live chat</h5>
                                                        With supporting text below as a natural lead-in to
                                                        additional
                                                        content.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="mx-3 btn-light btn-outline-secondary my-2 card ">
                                                <div className="card-body m-3 flex-raw d-flex justify-content-center align-items-center">
                                                    <svg style={{ height: 'auto', width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-4 bi bi-laptop" viewBox="0 0 16 16">
                                                        <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                                                    </svg>
                                                    <div>
                                                        <h5 className="card-title">Knowledge base + live chat</h5>
                                                        With supporting text below as a natural lead-in to
                                                        additional
                                                        content
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <h3>Bot</h3>
                                            {/* <div className="mx-3 my-2 card ">
                                                <div className="card-body">
                                                    <h5 className="card-title">Concierge</h5>
                                                    <p className="card-text">With supporting text below as a natural lead-in to
                                                        additional
                                                        content.</p>
                                                    <a href="#" className="btn btn-secondary">Button</a>
                                                </div>
                                            </div> */}
                                            <div className="mx-3 btn-light btn-outline-secondary my-2 card ">
                                                <div className="card-body m-3 flex-raw d-flex justify-content-center align-items-center">
                                                    <svg style={{ height: 'auto', width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-4 bi bi-robot" viewBox="0 0 16 16">
                                                        <path d="M6 12.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5ZM3 8.062C3 6.76 4.235 5.765 5.53 5.886a26.58 26.58 0 0 0 4.94 0C11.765 5.765 13 6.76 13 8.062v1.157a.933.933 0 0 1-.765.935c-.845.147-2.34.346-4.235.346-1.895 0-3.39-.2-4.235-.346A.933.933 0 0 1 3 9.219V8.062Zm4.542-.827a.25.25 0 0 0-.217.068l-.92.9a24.767 24.767 0 0 1-1.871-.183.25.25 0 0 0-.068.495c.55.076 1.232.149 2.02.193a.25.25 0 0 0 .189-.071l.754-.736.847 1.71a.25.25 0 0 0 .404.062l.932-.97a25.286 25.286 0 0 0 1.922-.188.25.25 0 0 0-.068-.495c-.538.074-1.207.145-1.98.189a.25.25 0 0 0-.166.076l-.754.785-.842-1.7a.25.25 0 0 0-.182-.135Z" />
                                                        <path d="M8.5 1.866a1 1 0 1 0-1 0V3h-2A4.5 4.5 0 0 0 1 7.5V8a1 1 0 0 0-1 1v2a1 1 0 0 0 1 1v1a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2v-1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1v-.5A4.5 4.5 0 0 0 10.5 3h-2V1.866ZM14 7.5V13a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V7.5A3.5 3.5 0 0 1 5.5 4h5A3.5 3.5 0 0 1 14 7.5Z" />
                                                    </svg>
                                                    <div>
                                                        <h5 className="card-title">Concierge</h5>
                                                        With supporting text below as a natural lead-in to
                                                        additional
                                                        content
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        {/* <div className="mx-3 my-2 card">
                                            <div className="card-body">
                                                <h5 className="card-title">Live chat</h5>
                                                <p className=" card-text">With supporting text below as a natural lead-in to
                                                    additional
                                                    content.</p>
                                                <a href="#" className="btn btn-secondary">Button</a>
                                            </div>
                                        </div> */}
                                        <div className="mx-3 btn-light btn-outline-secondary my-2 card ">
                                            <div className="card-body m-3 flex-raw d-flex justify-content-center align-items-center">
                                                <svg style={{ height: 'auto', width: '90px' }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="me-4 bi bi-pc-display-horizontal" viewBox="0 0 16 16">
                                                    <path d="M1.5 0A1.5 1.5 0 0 0 0 1.5v7A1.5 1.5 0 0 0 1.5 10H6v1H1a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1h-5v-1h4.5A1.5 1.5 0 0 0 16 8.5v-7A1.5 1.5 0 0 0 14.5 0h-13Zm0 1h13a.5.5 0 0 1 .5.5v7a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-7a.5.5 0 0 1 .5-.5ZM12 12.5a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0Zm2 0a.5.5 0 1 1 1 0 .5.5 0 0 1-1 0ZM1.5 12h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1 0-1ZM1 14.25a.25.25 0 0 1 .25-.25h5.5a.25.25 0 1 1 0 .5h-5.5a.25.25 0 0 1-.25-.25Z" />
                                                </svg>
                                                <div>
                                                    <h5 className="card-title">Concierge</h5>
                                                    With supporting text below as a natural lead-in to
                                                    additional
                                                    content
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    {/* chatbox  */}
                                    <div className=' col d-flex justify-content-center my-5'>
                                        <Chatbox></Chatbox>
                                    </div>
                                </div>
                            </div>
                            {/* third step container */}
                            <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                {/* third's container  */}
                                <div className="p-0 m-0 row">
                                    {/* 3rd first  */}
                                    <div style={{ minWidth: '336px' }} className="col card border-0 d-flex align-items-center" >
                                        <div className="" style={{ width: '300px' }}>

                                            <div className="" >
                                                <form>
                                                    <div className="mb-3">
                                                        <div >
                                                            <h5>You selected: <b>Live Chat</b></h5>
                                                            <label htmlFor="inputState" className="form-label">Select any input</label>
                                                            <select id="inputState" className="form-select">
                                                                <option use="">Inbox</option>
                                                                <option>...</option>
                                                            </select>
                                                        </div>
                                                        <div id="emailHelp" className="form-text">We'll never share your email with
                                                            anyone else.</div>
                                                    </div>
                                                    <div className="mb-3">
                                                        <div>
                                                            <label htmlFor="inputState" className="form-label">Select a lanugage</label>
                                                            <select id="inputState" className="form-select">
                                                                <option use="">English</option>
                                                                <option>...</option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                    {/* chatbox  */}
                                    <div className='col d-flex justify-content-center my-5'>
                                        <Chatbox></Chatbox>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
                {/* footer */}
                {/* last bg color:'#dee2e6' */}
                <footer style={{ backgroundColor: '', borderTop: '1px solid rgb(187 193 198)' }} className="bg-light mt-auto footer">
                    <div className='container'>
                        <nav aria-label="Page navigation example">
                            <ul className="m-3 pagination justify-content-between">
                                <div className='d-flex'>
                                    <a style={{ color: 'rgb(68 71 74)', cursor: 'pointer' }} className="me-4 d-flex align-items-center fw-bold text-decoration-underline " onClick={backToMainFun}>Cancel</a>
                                    <li className="page-item"><button className="btn btn-secondary">Previous</button></li>
                                </div>
                                <li className="page-item"><button className=" btn btn-secondary" onClick={showChatBoxFun} >Next</button></li>
                            </ul>
                        </nav>
                    </div>
                </footer>
            </div>
        </>
    )
}
