import React from 'react'

export default function alert(props) {
    
    return (
        <div>
            {/* alert box */}
            {props.callAlert && <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: '11' }}>
                <div id="liveToast" style={{minWidth:'350px',fontSize:'16px'}} className="w-100 toast show" role="alert" aria-live="assertive" aria-atomic="true">
                    <div className=" align-items-center   border-0" role="alert" aria-live="assertive" aria-atomic="true">
                        <div className="d-flex bg-primary text-white">
                            <div id='alartParagraph' className="toast-body">
                            {props.callAlert}
                            </div>
                            <button type="button" className="btn-close-white  btn-close me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}