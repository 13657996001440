import React from 'react'
import Container from '../Container/Container'
import Editchatbox from '../Createchatbox/Createchatbox';
import Stepper from '../Stepper/Stepper';
import Createchatbox from '../Createchatbox/Createchatbox';
import { useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';

const Home = (props) => {
  const [createChatBoxVar, setCrateChatBoxVar] = useState(true);
  const [showStepper, setShowStepper] = useState(true)
  const [istrue, setIstrue] = useState(false)
  const [rawElement, setRawElement] = useState(null)

  return (
    <>
      {/* {
      createChatBoxVar? <div>
       { istrue? <Editchatbox/>:<div>
        {showStepper?<Container showOrHide={showStepper => setShowStepper(showStepper)}></Container>
        :<Stepper showOrHide={showStepper => setShowStepper(showStepper)} showChatbox={createChatBoxVar => setCrateChatBoxVar(createChatBoxVar)} ></Stepper>} </div>}
      </div> :<Createchatbox forCallAlert={props.forCallAlert} logedInId={props.logedInId} showStepperFromCreateChatBox={showStepper => setShowStepper(showStepper)} showCreateChatBox={createChatBoxVar => setCrateChatBoxVar(createChatBoxVar)}></Createchatbox>
    } */}



      <Routes>
        <Route path="/" element={<Container forCallAlert={props.forCallAlert} rawElement={rawElement => setRawElement(rawElement)} editCreatechatBox={istrue => setIstrue(istrue)} />}></Route>
        <Route path="stepper" element={<Stepper showChatbox={createChatBoxVar => setCrateChatBoxVar(createChatBoxVar)} />}></Route>
        <Route path="createchatbox" element={<Createchatbox forCallAlert={props.forCallAlert} element={rawElement} showCreateChatBox={createChatBoxVar => setCrateChatBoxVar(createChatBoxVar)} />}></Route>
      </Routes>



    </>
  )
}

export default Home