import React from 'react'
import './box.css'
import chatboxlogo from '../images/chatbox_icon.png'
export default function Box(props) {
    return (
        <div>
            <section style={{
                border: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '18rem', height: '25rem', borderRadius: '15px 15px 15px 15px'
            }} className="m-3 col msger shadow" id="myForm">
                <div className=""
                    style={{ width: '100%', backgroundColor: '#5b3fff', borderRadius: '10px 10px 0px 0px', padding: '7px 12px', display: 'flex', alignContent: 'center' }}>
                    <div className="rounded-circle" id="logoPlaceHere" style={{ overflow: 'hidden', borderRadius: '100%' }}>
                        <img src={props.logoUrl ? props.logoUrl : chatboxlogo} id="forLogo" style={{ height: '50px', width: '50px' }} />
                    </div>
                    <div style={{ color: 'white', display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                        <h6 id="domainNameHere" style={{
                            fontFamily: 'Roboto Slab'
                        }} className="m-0">{props.nameOfChatBoxValue ? props.nameOfChatBoxValue : `Chatbox`}</h6>
                    </div>
                </div>

                <main style={{ width: '100%', borderRadius: '0px 0px 15px 15px' }} id="msger-chat" className="msger-chat">
                    <div style={{ wordBreak: 'break-word' }} id='welcomeMsg' className="msg left-msg">
                        <div className="msg-bubble">
                            <div className="msg-text" id="chatBoxTextarea">
                                {props.boxContent ? props.boxContent :`Got any question? I'm happy to help.` }
                            </div>
                        </div>
                    </div>
                </main>
                <form className="msger-inputarea border-top d-flex"
                    style={{ height: '55px', borderRadius: '0px 0px 10px 10px', backgroundColor: 'white', width: '100%', left: '0px', bottom: '0px', position: 'relative' }}>
                    <div>
                        <button style={{ borderRadius: '10px', border: 'none', outline: '0 none' }} className="p-3 bg-white h-100 w-100" type="submit">
                            <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor"
                                className="bi bi-send-fill" viewBox="0 0 16 16">
                                <path
                                    d="M15.964.686a.5.5 0 0 0-.65-.65L.767 5.855H.766l-.452.18a.5.5 0 0 0-.082.887l.41.26.001.002 4.995 3.178 3.178 4.995.002.002.26.41a.5.5 0 0 0 .886-.083l6-15Zm-1.833 1.89L6.637 10.07l-.215-.338a.5.5 0 0 0-.154-.154l-.338-.215 7.494-7.494 1.178-.471-.47 1.178Z" />
                            </svg>
                        </button>
                    </div>
                    <input style={{ margin: '12px 12px 12px 0px', borderRadius: 'inherit', outline: '0' }} id="textInput" autoComplete="off" type="text" className="msger-input border-0" />
                </form>

            </section>

        </div>
    )
}
