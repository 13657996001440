import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
    editChatboxDetails: {
        isError:false,
        isSuccess:false,
        isError:false,
    },

}

export const editDetailsToDB = createAsyncThunk('editChatboxDetails/editDetailsToDB', async ({adminPhNo, folderPath, timestamp, nameOfChatBoxValue, boxContent, logoSelected, boxId }) => {
    const formdata = new FormData();
    formdata.append('content', boxContent)
    formdata.append('modified', timestamp)
    formdata.append('logo', logoSelected)
    formdata.append('id', boxId)
    formdata.append('name', nameOfChatBoxValue)
    formdata.append('folderPath', folderPath)
    formdata.append('adminPhNo', adminPhNo)


    return await Axios.post(`${process.env.REACT_APP_NODE_URL}/api/updateChatBoxDetails`, formdata).then((response) => response.data
    ).catch(error => console.log(error))
})
const editDetailsSlice = createSlice({
    name: 'editChatboxDetails',
    initialState,
    reducers: {
        clearstateEdit: (state) => {
            state.editChatboxDetails.isError = false;
            state.editChatboxDetails.isSuccess = false;
            state.editChatboxDetails.isFetching = false;
            return state;
        },
    },
    extraReducers: builder => {
        builder.addCase(editDetailsToDB.pending, state => {
            // console.log("pending");
            state.editChatboxDetails.isFetching = true;
        })
        builder.addCase(editDetailsToDB.fulfilled, (state, action) => {
            state.editChatboxDetails.isFetching = false;
            state.editChatboxDetails.isSuccess = true;
            // console.log("Success");
        })
        builder.addCase(editDetailsToDB.rejected, (state, action) => {
            state.editChatboxDetails.isError = true;
            // console.log("Rejected");
        })


    }
})
export const { clearstateEdit } = editDetailsSlice.actions;
export default editDetailsSlice.reducer

