import axios from 'axios'
import React from 'react'
import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';

import { getDataFromChatboxDetail } from './ContainerSlice';
import { getDomainListFromDB } from '../AdminPanel/getDomainListSlice'


import './ConfirmScreen.css'


export default function ConfirmScreen(props) {
    const dispatch = useDispatch()
    useEffect(() => {
        const mainContainer = document.getElementById('mainContainer')
        mainContainer.style.filter = 'blur(3px)'
        return () => {
            mainContainer.style.filter = 'none'
        }
        if (props.screenFrom === 'container') {
            return () => {
                props.setDeleteId(null)
            }
        }
    }, [])
    function cancelHandler() {
        if (props.screenFrom === 'container') {
            props.setDeleteId(null)
        }
        props.setConfirmScreenState(false)
    }
    async function yesHandler() {
        if (props.screenFrom === 'container') {
            const url = `${process.env.REACT_APP_NODE_URL}/api/deactiveChatbot`
            const id = props.deleteId.id

            const token = localStorage.getItem('userIdInLocal')
            if (props.deleteId !== null) {
                await axios.post(url, { body: id, headers: { "Authorization": token } }).then((response) => {
                    if (response.data.isSuccess === true) {
                        dispatch(getDataFromChatboxDetail());
                        props.setConfirmScreenState(false)
                        props.setDeleteId(null)
                    } else {
                        props.forCallAlert('Something went wrong');
                    }
                }
                ).catch(error => console.log(error))
            }
        } else if (props.screenFrom === 'adminPanel') {
            const url = `${process.env.REACT_APP_NODE_URL}/api/deactiveUser`
            const id = props.currentData.data.id

            const token = localStorage.getItem('userIdInLocal')
            if (id !== null) {
                await axios.post(url, { body: id, isActive: props.currentData.event.target.checked, headers: { "Authorization": token } }).then((response) => {
                    if (response.data.isSuccess === true) {
                        dispatch(getDomainListFromDB())
                    } else {
                        props.forCallAlert('Something went wrong');
                    }
                    props.setConfirmScreenState(false)
                }
                ).catch(error => console.log(error))
            }

        }
    }
    return (
        <main className='confirmMain'>
            <section>
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
                    <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                    <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
                </svg>
                <h4>
                    {
                        props.screenFrom === 'container' ? 'Are you sure?' : props.currentData.event.target.checked === false ? 'Activate Account' : 'Deactivate Account'
                    }
                </h4>
                <p>
                    {
                        props.screenFrom === 'container' ? 'This action will delete all the things related to chatbox' : props.currentData.event.target.checked === false ? 'This action will make account usable.' : 'This action will make account permanently unusable.'
                    }
                </p>
                <div>
                    {/* <button onClick={props.setConfirmScreenState(false)} className='cancel'>Cancel</button> */}
                    <button onClick={cancelHandler} className='cancel'>Cancel</button>
                    <button onClick={yesHandler} className='delete'>{props.screenFrom === 'container' ? 'Yes, Delete it!' : props.currentData.event.target.checked === false ? 'Yes, Activate' : 'Yes, Deactivate'}</button>
                </div>
            </section>
        </main>
    )
}
