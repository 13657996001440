import { createAction, createSlice } from '@reduxjs/toolkit'

const initialState = { value: 0 }
const loadingSlice = createSlice({
    name: 'number',
    initialState,
    reducers: {
        setLoadingValue(state, action) {
            state.value = action.payload
            console.log(action.payload);

        },
    },
})

export const { setLoadingValue } = loadingSlice.actions
export default loadingSlice.reducer