import React, { useEffect, useState } from 'react'
import Chatbox from '../chatbox/box.js'
import { useNavigate } from 'react-router-dom';
import { saveDetailsToDB, clearState } from "./saveDetailsSlice";
import { editDetailsToDB, clearstateEdit } from "./editDetailsSlice";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

export default function CreateChatBox(props) {
    const [logoUrl, setLogoUrl] = useState(null)
    const [boxContent, setBoxContent] = useState('')
    const [logoSelected, setLogoSelected] = useState(null)
    const [folderPath, setFolderPath] = useState('');
    const [adminPhNo, setAdminPhNo] = useState('');
    const [nameOfChatBoxValue, setNameOfChatBoxValue] = useState('')
    const [createdOn, setCreatedOn] = useState(null)
    let [noRepeat, setNoRepeat] = useState(0)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const regId = localStorage.getItem('userIdInLocal');

    const { isFetching, isSuccess, isError, chatboxDetail, Message } = useSelector(
        (state) => state.saveChatboxuser.chatboxDetail);
    const newState = useSelector(
        (state) => state.saveChatboxuser.chatboxDetail);
    const editChatboxuser = useSelector(
        (state) => state.editChatboxuser);

    useEffect(() => {
        if (editChatboxuser.editChatboxDetails.isSuccess) {
            props.forCallAlert("Successfully saved!");
            dispatch(clearstateEdit());
            setNoRepeat(noRepeat = 1);
        }
    }, [editChatboxuser.editChatboxDetails.isSuccess])
    useEffect(() => {
        if (isError) {
            props.forCallAlert("Server is down, Please try again later!");
        }
    }, [isError])


    useEffect(() => {
        if (props.element) {
            setNameOfChatBoxValue(props.element.chatbox_name)
            setCreatedOn(props.element.created_on)
            setBoxContent(props.element.content)
            setLogoSelected(props.element.logo)
            setFolderPath(props.element.folderPath)
            setAdminPhNo(props.element.adminPhNo)
            setLogoUrl(`${process.env.REACT_APP_NODE_URL}/api${props.element.logo}`)
        }
    }, [])



    function toggleHandler() {
        const mainDiv = document.getElementById('mainDiv')
        const chatBox = document.getElementById('chatBox')
        const toggleBtn = document.getElementById('toggleBtn')

        if (toggleBtn.checked === true) {
            mainDiv.classList.add('w-100')
            mainDiv.classList.add('px-lg-5')
            chatBox.classList.add('d-none')
        }
        else {
            mainDiv.classList.remove('w-100')
            mainDiv.classList.remove('px-lg-5')
            chatBox.classList.remove('d-none')
        }
    }

    function folderPathInputHandler(e) {
        const folderPath = document.getElementById('folderPath')
        // Update the folderPath state with the new value
        folderPath.innerText = `${e.target.value}`
        setFolderPath(e.target.value);
    }

    function AdminPhNoInputHandler(e) {
        const adminPhNo = document.getElementById('adminPhNo')
        // Update the adminPhNo state with the new value
        adminPhNo.innerText = `${e.target.value}`
        setAdminPhNo(e.target.value);
    }



    function chatBoxContent(e) {
        setBoxContent(e.target.value)
        const chatBoxTextarea = document.getElementById('chatBoxTextarea')
        chatBoxTextarea.innerText = `${e.target.value}`
    }
    function logoChangeHandler(e) {
        setLogoSelected(e.target.files[0])
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setLogoUrl(reader.result)
                console.log('logo changed here', reader.result)
            } else { }
        }
        reader.readAsDataURL(e.target.files[0])
    }
    function getCurrentTime() {
        let newD = new Date();
        const timestamp = `${newD.getDate()}-${newD.getMonth() + 1}-${newD.getFullYear()} ${newD.getHours()}:${newD.getMinutes()}:${newD.getSeconds()}`
        return timestamp
    }
    function chatBoxNameChange(e) {
        const timestamp = getCurrentTime()
        setNameOfChatBoxValue(e.target.value)
        const displayNameOfChatBox = document.getElementById('displayNameOfChatBox')
        setCreatedOn(timestamp)
        displayNameOfChatBox.innerText = `${e.target.value} (${timestamp})`
    }
    useEffect(() => {
        if (isSuccess) {
            props.forCallAlert("Successfully saved!");
            dispatch(clearState());
            setNoRepeat(noRepeat = 1);
        }
    }, [isSuccess])
    function saveChanges() {

        if (noRepeat === 0) {
            if (props.element) {
                if (nameOfChatBoxValue.length > 3 && boxContent.length > 3) {
                    const timestamp = getCurrentTime()
                    const boxId = props.element.id
                    dispatch(editDetailsToDB({ adminPhNo, folderPath, timestamp, nameOfChatBoxValue, boxContent, logoSelected, boxId }))
                    console.log('All here', adminPhNo, folderPath, timestamp, nameOfChatBoxValue, boxContent, logoSelected, boxId);

                }
                else {
                    props.forCallAlert('Name and Welcome messege have minimum 4 characters!')
                }
            }
            else {
                if (logoSelected && nameOfChatBoxValue && boxContent && folderPath && adminPhNo) {
                    if (logoSelected != null && nameOfChatBoxValue.length > 3 && boxContent.length > 3) {
                        const timestamp = getCurrentTime()

                        dispatch(saveDetailsToDB({ adminPhNo, folderPath, timestamp, nameOfChatBoxValue, boxContent, logoSelected, regId }))

                    }
                    else {
                        props.forCallAlert('Chatbox Name and Welcome messege have minimum 4 characters')
                    }
                }
                else {
                    props.forCallAlert('Please fill all the field')
                }
            }
        }
        else {
            console.log('Folder path', folderPath);
            console.log('Admin Number', adminPhNo);
            props.forCallAlert('Saved,If you want to edit, Edit it from the lobby')
        }
    }


    function backToSteppers() {
        if (props.element === null) {
            navigate("/home/stepper");
        } else {
            navigate("/home");
        }
    }
    return (
        <>
            <nav className="p-lg-3  p-2 navbar-dark" style={{ backgroundColor: '#403939' }}>
                <div className="d-flex flex-wrap align-items-center justify-content-center justify-content-lg-between ">
                    <a className="ms-3 d-flex align-items-center navbar-brand btn " onClick={backToSteppers}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                            <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                        </svg>
                        Back to chatflows</a>
                    <span id='displayNameOfChatBox' className="fs-4 nav text-white col-12 col-md-auto mb-2 justify-content-center mb-md-0">{nameOfChatBoxValue === '' ? `Chatbox'name here` : nameOfChatBoxValue}({createdOn === null ? `created on` : createdOn})</span>
                    <div className="d-flex mx-3">
                        <button onClick={saveChanges} className="btn btn-outline-light " type="submit">Save</button>
                        <span className="btn text-white">Preview</span>
                        <div className=" form-check form-switch">
                            <input onChange={toggleHandler} id="toggleBtn" style={{
                                height: '30px',
                                width: '60px',
                                borderRadius: '10px'
                            }} className="form-check-input" type="checkbox" role="switch" />
                        </div>
                    </div>
                </div>
            </nav>
            <div className="card border-0">
                <div className=" d-flex flex-column align-items-center ">
                    {/* Bordered Tabs */}
                    <div style={{ zIndex: '1' }} className='position-sticky top-0 bg-white  shadow w-100' >
                        <ul className="justify-content-center nav nav-tabs" id="borderedTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button style={{ width: '180px' }} className="nav-link my active" id="build-tab" data-bs-toggle="tab"
                                    data-bs-target="#bordered-build" type="button" role="tab" aria-controls="build"
                                    aria-selected="true">Build</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button style={{ width: '180px' }} className="my nav-link" id="target-tab" data-bs-toggle="tab"
                                    data-bs-target="#bordered-target" type="button" role="tab" aria-controls="target"
                                    aria-selected="false">Target</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button style={{ width: '180px' }} className="my nav-link" id="display-tab" data-bs-toggle="tab"
                                    data-bs-target="#bordered-display" type="button" role="tab" aria-controls="display"
                                    aria-selected="false">Display</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button style={{ width: '180px' }} className="my nav-link" id="option-tab" data-bs-toggle="tab"
                                    data-bs-target="#bordered-option" type="button" role="tab" aria-controls="option"
                                    aria-selected="false">Option</button>
                            </li>
                        </ul>
                    </div>
                    <div className="w-100 d-flex row " id="borderedTabContent">
                        {/* four tabs build,target */}
                        <div id='mainDiv' style={{ overflowY: 'overlay', height: '80vh' }} className=' ps-lg-5 col-lg-6   tab-content'>
                            {/* *****Build***** */}
                            <div className="btdo build  tab-pane fade show active" id="bordered-build" role="tabpanel" aria-labelledby="build-tab">
                                <div className="d-inline-flex justify-content-between row m-0" style={{ width: '100%' }}>
                                    {/* first column of Build   */}
                                    {/* style={{ height: '75vh' }} */}
                                    <div className="col px-3 card border-0  ">
                                        <div className=" ">
                                            <h3>Build</h3>
                                            <small>Create a welcome messege to greet site visitor</small>
                                            <hr />
                                        </div>
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            {/* acordation item 0  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="buildpanelsStayOpen-headingZero">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#buildpanelsStayOpen-collapseZero" aria-expanded="true"
                                                        aria-controls="buildpanelsStayOpen-collapseZero">
                                                        Add name & logo
                                                    </button>
                                                </h2>
                                                <div id="buildpanelsStayOpen-collapseZero" className="accordion-collapse collapse show"
                                                    aria-labelledby="buildpanelsStayOpen-headingZero">
                                                    <div className="accordion-body">
                                                        <div>
                                                            <div className="form-group">
                                                                <label htmlFor="formFile" className="form-label">Name of chatbox</label>
                                                                {/* <label htmlFor="exampleInputEmail1">Email address</label> */}
                                                                <input type="text" className="form-control" onChange={chatBoxNameChange} id="nameOfChatBox" aria-describedby="emailHelp" placeholder="Enter name" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="accordion-body">
                                                        <div className="mb-3">
                                                            <label htmlFor="formFile" className="form-label">Choose logo</label>
                                                            <input onChange={logoChangeHandler} accept="image/*" className="form-control" type="file" id="formFile" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* acordation item 1  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="buildpanelsStayOpen-headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#buildpanelsStayOpen-collapseOne" aria-expanded="true"
                                                        aria-controls="buildpanelsStayOpen-collapseOne">
                                                        Welcome Message
                                                    </button>
                                                </h2>
                                                <div id="buildpanelsStayOpen-collapseOne" className="accordion-collapse collapse show"
                                                    aria-labelledby="buildpanelsStayOpen-headingOne">
                                                    <div className="accordion-body">
                                                        <p>Customize here</p>
                                                        <h6>Write a welcome messege</h6>
                                                        <div className="form-floating">
                                                            <textarea onChange={chatBoxContent} className="form-control" placeholder="Leave a comment here"
                                                                id="floatingTextarea1" style={{ height: '100px' }}></textarea>
                                                            <label htmlFor="floatingTextarea1">Got any questions?I'm happy to
                                                                help</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* acordation item 2  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="buildpanelsStayOpen-headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#buildpanelsStayOpen-collapseOne" aria-expanded="true"
                                                        aria-controls="buildpanelsStayOpen-collapseOne">
                                                        Folder path
                                                    </button>
                                                </h2>
                                                <div id="buildpanelsStayOpen-collapseOne" className="accordion-collapse collapse show"
                                                    aria-labelledby="buildpanelsStayOpen-headingOne">
                                                    <div className="accordion-body">
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text">Folder path:</span>
                                                            <input type="text" id="folderPath" onChange={folderPathInputHandler} className="form-control" placeholder="Enter path here" aria-label="Enter path" aria-describedby="basic-addon2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* acordation item 3  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="buildpanelsStayOpen-headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#buildpanelsStayOpen-collapseOne" aria-expanded="true"
                                                        aria-controls="buildpanelsStayOpen-collapseOne">
                                                        Admin Phone number
                                                    </button>
                                                </h2>
                                                <div id="buildpanelsStayOpen-collapseOne" className="accordion-collapse collapse show"
                                                    aria-labelledby="buildpanelsStayOpen-headingOne">
                                                    <div className="accordion-body">
                                                        <div className="input-group mb-3">
                                                            <span className="input-group-text">Admin Phone number:</span>
                                                            <input type="text" id="adminPhNo" onChange={AdminPhNoInputHandler} className="form-control" placeholder="Enter number here" aria-label="Enter path" aria-describedby="basic-addon2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* acordation item 4  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="buildpanelsStayOpen-headingTwo">
                                                    <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#buildpanelsStayOpen-collapseTwo" aria-expanded="true"
                                                        aria-controls="buildpanelsStayOpen-collapseTwo">
                                                        Knowledge Base search
                                                    </button>
                                                </h2>
                                                <div id="buildpanelsStayOpen-collapseTwo" className="accordion-collapse collapse show"
                                                    aria-labelledby="buildpanelsStayOpen-headingTwo">
                                                    <div className="row accordion-body">
                                                        <div className="col-10">

                                                            <strong>Enable Knowledge Base search</strong>
                                                            <br />
                                                            To manage or edit the articles
                                                        </div>
                                                        <div className="col-2 form-check form-switch">
                                                            <input style={{
                                                                height: '30px',
                                                                width: '60px',
                                                                borderRadius: '10px'
                                                            }} className="form-check-input" type="checkbox"
                                                                role="switch" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* acordation item 5  */}
                                            {/* if we want to show closed dropdown change below prop
                                in btn >>remove collapsed, aria-expanded=true
                                in div >>add class show  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="buildpanelsStayOpen-headingThree">
                                                    <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#buildpanelsStayOpen-collapseThree" aria-expanded="true"
                                                        aria-controls="buildpanelsStayOpen-collapseThree">
                                                        Automatically assign conversation
                                                    </button>
                                                </h2>
                                                <div id="buildpanelsStayOpen-collapseThree" className="accordion-collapse collapse show"
                                                    aria-labelledby="buildpanelsStayOpen-headingThree">
                                                    <div className="row accordion-body">
                                                        <div className="col-10">
                                                            New conversation will be assigned to the selected team members.
                                                        </div>
                                                        <div className="col-2 form-check form-switch">
                                                            <input style={{
                                                                height: '30px',
                                                                width: '60px',
                                                                borderRadius: '10px'
                                                            }} className="form-check-input" type="checkbox"
                                                                role="switch" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* acordation item 6  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="buildpanelsStayOpen-headingForth">
                                                    <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#buildpanelsStayOpen-collapseForth" aria-expanded="true"
                                                        aria-controls="buildpanelsStayOpen-collapseForth">
                                                        Email capture
                                                    </button>
                                                </h2>
                                                <div id="buildpanelsStayOpen-collapseForth" className="show accordion-collapse collapse"
                                                    aria-labelledby="buildpanelsStayOpen-headingForth">
                                                    <div className=" accordion-body">
                                                        <div className="mb-2">
                                                            Ask visitors for heir email address
                                                        </div>
                                                        <div className="dropdown">
                                                            <button style={{ whiteSpace: 'break-spaces' }} className=" btn btn-secondary dropdown-toggle" type="button"
                                                                id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="expanded">
                                                                immediately after first messge is sent
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-dark"
                                                                aria-labelledby="dropdownMenuButton2">
                                                                <li><a className="dropdown-item active" href="#">Action</a></li>
                                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                                <li>
                                                                    <hr className="dropdown-divider" />
                                                                </li>
                                                                <li><a className="dropdown-item" href="#">Separated link</a></li>
                                                            </ul>
                                                        </div>

                                                        <h6 className="mt-2">Email capture messege</h6>
                                                        <div className="form-floating">
                                                            <textarea className="form-control" placeholder="Leave a comment here"
                                                                id="floatingTextarea2" style={{ height: '100px' }}></textarea>
                                                            <label htmlFor="floatingTextarea2">Don't have time to wait for a
                                                                response?Leave your email</label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* second column of Build  */}

                                </div>
                            </div>
                            {/* *****Target***** */}
                            <div className="btdo target tab-pane fade" id="bordered-target" role="tabpanel" aria-labelledby="target-tab">
                                <div className='px-3'>
                                    <h3 className="mb-2">Target</h3>
                                    <small className="mb-3">Choose which pages the chat widget should appear on,and who it should appear
                                        for.</small>
                                    <hr />
                                    <h5 className="mb-2">Website URL</h5>
                                    <p className="mb-3">Target your visitorss by choosing the web pages where you'd like your chatflow
                                        to appear</p>
                                    {/* div for show chat  */}
                                    {/* new container from options  */}
                                    <div className="card mb-3 border rounded-3">
                                        <div className="card-body p-4">
                                            <div className="d-flex justify-content-center ">
                                                <div>
                                                    <h4>Show chat</h4>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <h6 className="m-0 text-monospace">WHEN</h6>
                                                <div className="mx-2 dropdown">
                                                    <button style={{ whiteSpace: 'break-spaces' }} className="btn btn-secondary dropdown-toggle" type="button"
                                                        id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Website URL
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                                        <li><a className="dropdown-item active" href="#">Action</a></li>
                                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        <li>
                                                            <hr className="dropdown-divider" />
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">Separated link</a></li>
                                                    </ul>
                                                </div>
                                                <div className="mx-2 dropdown">
                                                    <button style={{ whiteSpace: 'break-spaces' }} className="btn btn-secondary dropdown-toggle" type="button"
                                                        id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        is all pages
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                                        <li><a className="dropdown-item active" href="#">Action</a></li>
                                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        <li>
                                                            <hr className="dropdown-divider" />
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">Separated link</a></li>
                                                    </ul>
                                                </div>


                                            </div>
                                        </div>
                                    </div>


                                    <h5 className="mb-2">Visitor information and behavior(optional)</h5>
                                    <p className="mb-3">Have more control over who sees your chatflow
                                        to appear</p>
                                    <div className="card mb-3 border rounded-3">
                                        <div className="card-body p-4 ">
                                            <div className=" d-flex justify-content-center ">
                                                <div>
                                                    <h4>Show chat</h4>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <h6 className="m-0 text-monospace">WHEN</h6>
                                                <div className="mx-2 dropdown">
                                                    <button className="btn btn-secondary dropdown-toggle" type="button"
                                                        id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="false">
                                                        Select filter
                                                    </button>
                                                    <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                                        <li><a className="dropdown-item active" href="#">Action</a></li>
                                                        <li><a className="dropdown-item" href="#">Another action</a></li>
                                                        <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                        <li>
                                                            <hr className="dropdown-divider" />
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">Separated link</a></li>
                                                    </ul>
                                                </div>
                                                <p className='m-0'>(optional)</p>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            {/* *****Display***** */}
                            <div className="btdo display tab-pane fade" id="bordered-display" role="tabpanel" aria-labelledby="display-tab">
                                <div className="d-inline-flex justify-content-between row w-100 m-0">
                                    {/* first column of Build   */}
                                    {/* style={{ height: '75vh' }} */}
                                    <div className="col px-3 card border-0  ">
                                        <div className=" ">
                                            <h3>Display</h3>
                                            <small>Customize the chart heading chat display behavior, and timing controls.</small>
                                            <hr />
                                        </div>
                                        <div className="accordion" id="accordionPanelsStayOpenExample">
                                            {/* acordation item 1  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="targetpanelsStayOpen-headingOne">
                                                    <button className="accordion-button" type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#targetpanelsStayOpen-collapseOne" aria-expanded="true"
                                                        aria-controls="targetpanelsStayOpen-collapseOne">
                                                        Choose chat avtar
                                                    </button>
                                                </h2>
                                                <div id="targetpanelsStayOpen-collapseOne" className="accordion-collapse collapse show"
                                                    aria-labelledby="targetpanelsStayOpen-headingOne">
                                                    <div className="accordion-body">
                                                        <p>Add a custom image or logo to give your chat a personalized touch.</p>
                                                        <h6>Chat heading</h6>

                                                        <div className="mx-2 my-2 dropdown">
                                                            <button style={{ whiteSpace: 'break-spaces' }} className="btn btn-secondary dropdown-toggle" type="button"
                                                                id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                Specific users and teams
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-dark"
                                                                aria-labelledby="dropdownMenuButton2">
                                                                <li><a className="dropdown-item active" href="#">Action</a></li>
                                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                                <li>
                                                                    <hr className="dropdown-divider" />
                                                                </li>
                                                                <li><a className="dropdown-item" href="#">Separated link</a></li>
                                                            </ul>
                                                        </div>
                                                        <div className="mx-2 my-2 dropdown">
                                                            <button style={{ whiteSpace: 'break-spaces' }} className="btn btn-secondary dropdown-toggle" type="button"
                                                                id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                                                aria-expanded="false">
                                                                Content here
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-dark"
                                                                aria-labelledby="dropdownMenuButton2">
                                                                <li><a className="dropdown-item active" href="#">Action</a></li>
                                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                                <li>
                                                                    <hr className="dropdown-divider" />
                                                                </li>
                                                                <li><a className="dropdown-item" href="#">Separated link</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* acordation item 2  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="targetpanelsStayOpen-headingTwo">
                                                    <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#targetpanelsStayOpen-collapseTwo" aria-expanded="true"
                                                        aria-controls="targetpanelsStayOpen-collapseTwo">
                                                        Chat display behavior
                                                    </button>
                                                </h2>
                                                <div id="targetpanelsStayOpen-collapseTwo" className="show accordion-collapse collapse"
                                                    aria-labelledby="targetpanelsStayOpen-headingTwo">
                                                    <div className=" accordion-body">
                                                        <div className="">
                                                            <small>Customize how the chatflow will appear to targeted visitors for
                                                                desktop, tablet and mobile.</small>
                                                        </div>
                                                        <div className="card border-0">
                                                            <div className="card-body">

                                                                {/* Default Tabs  */}
                                                                <ul className="nav nav-tabs d-flex" id="myTabjustified" role="tablist">
                                                                    <li className="nav-item flex-fill" role="presentation">
                                                                        <button className="nav-link w-100 active" id="home-tab"
                                                                            data-bs-toggle="tab" data-bs-target="#home-justified"
                                                                            type="button" role="tab" aria-controls="home"
                                                                            aria-selected="true">Desktop</button>
                                                                    </li>
                                                                    <li className="nav-item flex-fill" role="presentation">
                                                                        <button className="nav-link w-100 " id="profile-tab"
                                                                            data-bs-toggle="tab" data-bs-target="#profile-justified"
                                                                            type="button" role="tab" aria-controls="profile"
                                                                            aria-selected="false">Mobile</button>
                                                                    </li>

                                                                </ul>
                                                                <div className="tab-content pt-2 fade active show"
                                                                    id="myTabjustifiedContent">
                                                                    <div className="tab-pane active" id="home-justified" role="tabpanel"
                                                                        aria-labelledby="home-tab">
                                                                        Decide the chatflow's default state when triggers are
                                                                        met.Desktop display
                                                                    </div>
                                                                    <div className="tab-pane " id="profile-justified" role="tabpanel"
                                                                        aria-labelledby="profile-tab">
                                                                        Nesciunt totam et. Consequuntur magnam aliquid eos nulla
                                                                        dolor iure eos quia. Accusantium distinctio omnis et atque
                                                                        fugiat. Itaque doloremque aliquid sint quasi quia distinctio
                                                                        similique. Voluptate nihil recusandae mollitia dolores. Ut
                                                                        laboriosam voluptatum dicta.
                                                                    </div>

                                                                </div>
                                                                {/* End Default Tabs  */}

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                            {/* acordation item 3  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="targetpanelsStayOpen-headingThree">
                                                    <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#targetpanelsStayOpen-collapseThree" aria-expanded="true"
                                                        aria-controls="targetpanelsStayOpen-collapseThree">
                                                        Automatically assign conversation
                                                    </button>
                                                </h2>
                                                <div id="targetpanelsStayOpen-collapseThree" className=" show accordion-collapse collapse"
                                                    aria-labelledby="targetpanelsStayOpen-headingThree">
                                                    <div className="row accordion-body">
                                                        <div className="col-10">
                                                            New conversation will be assigned to the selected team members.
                                                        </div>
                                                        <div className="col-2 form-check form-switch">
                                                            <input style={{
                                                                height: '30px',
                                                                width: '60px',
                                                                borderRadius: '10px'
                                                            }} className="form-check-input" type="checkbox"
                                                                role="switch" id="flexSwitchCheckDefault" />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* acordation item 4  */}
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id="targetpanelsStayOpen-headingForth">
                                                    <button className="accordion-button " type="button" data-bs-toggle="collapse"
                                                        data-bs-target="#targetpanelsStayOpen-collapseForth" aria-expanded="true"
                                                        aria-controls="targetpanelsStayOpen-collapseForth">
                                                        Email capture
                                                    </button>
                                                </h2>
                                                <div id="targetpanelsStayOpen-collapseForth" className="show accordion-collapse collapse"
                                                    aria-labelledby="targetpanelsStayOpen-headingForth">
                                                    <div className=" accordion-body">
                                                        <div className="mb-2">
                                                            Ask visitors for heir email address
                                                        </div>
                                                        <div className="dropdown">
                                                            <button style={{ whiteSpace: 'break-spaces' }} className=" btn btn-secondary dropdown-toggle" type="button"
                                                                id="dropdownMenuButton2" data-bs-toggle="dropdown" aria-expanded="">
                                                                immediately after first messge is sent
                                                            </button>
                                                            <ul className="dropdown-menu dropdown-menu-dark"
                                                                aria-labelledby="dropdownMenuButton2">
                                                                <li><a className="dropdown-item active" href="#">Action</a></li>
                                                                <li><a className="dropdown-item" href="#">Another action</a></li>
                                                                <li><a className="dropdown-item" href="#">Something else here</a></li>
                                                                <li>
                                                                    <hr className="dropdown-divider" />
                                                                </li>
                                                                <li><a className="dropdown-item" href="#">Separated link</a></li>
                                                            </ul>
                                                        </div>

                                                        <h6 className="mt-2">Email capture messege</h6>
                                                        <div className="form-floating">
                                                            <textarea className="form-control" placeholder="Don't have time to wait for a response Leave your email"
                                                                id="floatingTextarea2" style={{ height: '100px' }}></textarea>
                                                            <label htmlFor="floatingTextarea2"></label>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            {/* *****Options***** */}
                            <div className="m-0 btdo option tab-pane fade" id="bordered-option" role="tabpanel" aria-labelledby="option-tab">
                                <div className='px-3'>
                                    <h3 className="mb-2">Options</h3>
                                    <small className="mb-2">Language</small>
                                    <hr />
                                    <h6>Select a language</h6>
                                    <div className="row m-2 dropdown">
                                        <button className="col col-lg-8 col-md-9  btn btn-secondary dropdown-toggle" type="button" id="dropdownMenuButton2"
                                            data-bs-toggle="dropdown" aria-expanded="false">
                                            English
                                        </button>
                                        <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="dropdownMenuButton2">
                                            <li><a className="dropdown-item active" href="#">Action</a></li>
                                            <li><a className="dropdown-item" href="#">Another action</a></li>
                                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                                            <li>
                                                <hr className="dropdown-divider" />
                                            </li>
                                            <li><a className="dropdown-item" href="#">Separated link</a></li>
                                        </ul>
                                    </div>

                                    <h4 className="py-3 fw-bold">Data privacy & consent</h4>
                                    <div className="">
                                        {/* first container  */}
                                        <div className="p-lg-5 p-4 p-md-5 mb-4 border rounded-3">
                                            <div className="">
                                                <h5 className="col-md-8 fs-4">Consent to collect chat cookies.</h5>
                                                <div className="row">
                                                    <p className="col-sm-11">If your website visitors leaves the page thst they began
                                                        chatting
                                                        on but did not
                                                        accept your website cookie conseent banner, the chat widget will reset and the
                                                        existing conversation will end.</p>
                                                    <div className="col-sm-1 form-check form-switch">
                                                        <input style={{
                                                            height: '30px',
                                                            width: '60px',
                                                            borderRadius: '10px'
                                                        }} className="form-check-input" type="checkbox" role="switch"
                                                            id="flexSwitchCheckDefault" />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* second container  */}
                                        <div className="p-lg-5 p-4 p-md-5 mb-4 border rounded-3">
                                            <div className=" ">
                                                <h5 className="col-md-8 fs-4">Consent to process data</h5>
                                                <div className="row">
                                                    <p className="col-sm-11">Use the text field below to explain why you need to store and
                                                        process your customer's personal information.</p>
                                                    <div className="col-sm-1 form-check form-switch">
                                                        <input style={{
                                                            height: '30px',
                                                            width: '60px',
                                                            borderRadius: '10px'
                                                        }} className="form-check-input" type="checkbox" role="switch"
                                                            id="flexSwitchCheckDefault" />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* third container  */}
                                        <div className="p-lg-5 p-4 p-md-5 mb-4 border rounded-3">
                                            <div className="">
                                                <h5 className="col-md-8 fs-4">Consent to communicate</h5>
                                                <div className="row">
                                                    <p className="col-sm-11">Use the text field below to tell customers aht type of
                                                        communication they're consenting to.</p>
                                                    <div className="col-sm-1 form-check form-switch">
                                                        <input style={{
                                                            height: '30px',
                                                            width: '60px',
                                                            borderRadius: '10px'
                                                        }} className="form-check-input" type="checkbox" role="switch"
                                                            id="flexSwitchCheckDefault" />
                                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault"></label>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* container without border  */}
                                        <div className=" p-3 p-md-5 p-lg-5 mb-4  rounded-3">
                                            <div className="container-fluid ">
                                                <h5 className=" fs-4">Collect feedback from chat visitors</h5>
                                                <div className="row">
                                                    <p className="col-sm-11">You can connected a CSAT survey to your chatflow and ask
                                                        visitors for feedback when a chat conversation is closed.</p>
                                                    <div className="">
                                                        <button type="button" className="px-3 py-2 btn btn-dark">Create new survey</button>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                        {/* chatbox  */}
                        <div id='chatBox' style={{ zIndex: '2', backgroundColor: '#f5f5f5' }} className="pe-lg-5  col-lg-6 d-flex justify-content-center align-items-center">

                            <Chatbox logoUrl={logoUrl} nameOfChatBoxValue={nameOfChatBoxValue} folderPath={folderPath} adminPhNo={adminPhNo} boxContent={boxContent}></Chatbox>
                        </div>

                    </div>
                    {/* <div style={{ height: '46px', position: 'relative' }} className='w-100'></div> */}

                </div>
            </div>
        </>
    )
}
