import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';



const initialState = {
  Loginuser: {
    isFetching: false,
    isSuccess: false,
    isError: false,
    loginData: []
  },
}



// export const getRegisterId = createAsyncThunk('user/getRegisterId', async ({ email }) => {
//   return await Axios.post(`${process.env.REACT_APP_NODE_URL}/api/getRegisterId`, {
//     emailId: email
//   }).then(response => localStorage.setItem('userIdInLocal', response.data[0].id)
//   ).catch(error => console.log(error))
// })
export const getLoginUserDataAsync = createAsyncThunk('Loginuser/getLoginUserDataAsync', async (loginDetails) => {
  return await Axios.post(`${process.env.REACT_APP_NODE_URL}/api/logInChatbox`, {
    email: loginDetails.emailId, password: loginDetails.password
  }).then((response) => response.data
  )
})



const LoginUserSlice = createSlice({
  name: 'Loginuser',
  initialState,
  reducers: {
    clearStateLogin: (state) => {
      state.Loginuser.isError = false;
      state.Loginuser.isSuccess = false;
      state.Loginuser.isFetching = false;
      state.Loginuser.loginData = [];
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(getLoginUserDataAsync.pending, state => {
      // console.log("pending");
      state.Loginuser.isFetching = true;
    })
    builder.addCase(getLoginUserDataAsync.fulfilled, (state, action) => {
      state.Loginuser.isFetching = false;
      // console.log('Loginuser action.payload',action.payload)
      if (action.payload) {
        state.Loginuser.isSuccess = true;
        state.Loginuser.loginData = action.payload;
      }

    })
    builder.addCase(getLoginUserDataAsync.rejected, (state, action) => {
      // console.log("Rejected");
      state.Loginuser.isFetching = false;
      state.Loginuser.isError = true;
      console.log('login error', action.error)
    })

  }
})
export const { clearStateLogin } = LoginUserSlice.actions;
export default LoginUserSlice.reducer

