import Axios from 'axios';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

const initialState = {
  visitorList: {
    isError:false,
    isSuccess:false,
    isError:false,
    data:[]
  },

}

export const getVisitorListFromDB = createAsyncThunk('visitorList/getVisitorListFromDB', async ({ register_id}) => {
  return await Axios.post(`${process.env.REACT_APP_NODE_URL}/api/visitor_list`, {register_id:register_id}).then((response) => response.data
  ).catch(error => console.log(error))
})
const getVisitorListSlice = createSlice({
  name: 'visitorList',
  initialState,
  reducers: {
    clearStateVList: (state) => {
      state.visitorList.isError = false;
      state.visitorList.isSuccess = false;
      state.visitorList.isFetching = false;
      state.visitorList.data = [];
      return state;
    },
  },
  extraReducers: builder => {
    builder.addCase(getVisitorListFromDB.pending, state => {
      // console.log("pending");
      state.visitorList.isFetching = true;
    })
    builder.addCase(getVisitorListFromDB.fulfilled, (state, action) => {
      state.visitorList.data = action.payload?.result;
      state.visitorList.isFetching = false;
      state.visitorList.isSuccess = true;
      // state.visitorList.Message = action.payload.Message;
      // console.log("Success");
    })
    builder.addCase(getVisitorListFromDB.rejected, (state, action) => {
      // console.log("Rejected");
      state.visitorList.isError = true;
    })


  }
})
export const { clearStateVList } = getVisitorListSlice.actions;
export default getVisitorListSlice.reducer

