import React from 'react';
import { useState } from 'react';
import { getDataFromChatboxDetail } from './ContainerSlice';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NavBar from '../Navbar/Navbar';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';
import ConfirmScreen from './ConfirmScreen';
let ReturnfolderPath = '';
let ReturnadminPhNo = '';
const Container = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    // const getRegId = localStorage.getItem('userIdInLocal')
    const { isFetching, isSuccess, isError, GetChatboxData } = useSelector((state) => state.chatboxuser.chatboxData);

    const [confirmScreenState, setConfirmScreenState] = useState(false)
    const [deleteId, setDeleteId] = useState(null)
    useEffect(() => {
        dispatch(getDataFromChatboxDetail());
    }, []);
    useEffect(() => {
        console.log('deleteId', deleteId);
    }, [deleteId]);




    let g = 0

    function createChatfloxFnc(e) {
        const mainContainer = document.getElementById('mainContainer');
        mainContainer.style.display = 'none';
        //props.showOrHide(false)
        navigate("/home/stepper")
    }

    function deleteChatbot(a) {
        setDeleteId(a)
        if (confirmScreenState) {
            setConfirmScreenState(false)
        } else {
            setConfirmScreenState(true)
        }
    }

    function copyScripttag(id, folderPath, adminPhNo) {
        const scriptPath = `<script data-ChatBox-Id="${id}" ReturnfolderPath="${folderPath}" Admin-PhNo="${adminPhNo}" id="mainScript" src="${process.env.REACT_APP_NODE_URL}/exportjs/exportBox.js" ></script>`;

        ReturnfolderPath = folderPath; // Update the variable
        ReturnadminPhNo = adminPhNo;
        console.log('ReturnadminPhNo', ReturnadminPhNo);
        console.log('ReturnfolderPath', ReturnfolderPath);

        navigator.clipboard.writeText(scriptPath);
    }


    function editCreateChatBoxHandler(element) {
        navigate("/home/createchatbox");
        props.rawElement(element);
    }
    useEffect(() => {
        props.rawElement(null)
    }, [])
    return (
        <>
            <NavBar />
            <div id="mainContainer" className="">
                <div className='container d-flex mt-2 align-items-center justify-content-between'>
                    <h3 className="m-0">Chatflows</h3>
                    <button style={{ backgroundColor: 'rgb(159 183 0)' }} onClick={createChatfloxFnc} className=" btn text-white px-3 py-2 ">Create chatflow</button>

                </div>
                {/* div1 it contain table  */}
                <div id="div1" className="" >
                    <div className='border-bottom'>
                        <ul className="container nav nav-tabs border-0" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button style={{ color: '#495057', fontWeight: '500' }} className=" nav-link my active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                                    type="button" role="tab" aria-controls="home" aria-selected="true">Chatbox</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button style={{ color: '#495057', fontWeight: '500' }} className=" nav-link my" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                                    type="button" role="tab" aria-controls="profile" aria-selected="false">Facebook
                                    Messenger</button>
                            </li>
                        </ul>
                    </div>

                    <div className="container tab-content " id="myTabContent">

                        <div className="tab-pane fade active show" id="home" role="tabpanel" aria-labelledby="home-tab">
                            <p className="d-block text-start my-4">
                                To customize your widget's appearance and availability,go to your

                                <a style={{ fontWeight: '700' }} className='text-secondary text-decoration-none ' href="#"> inbox setting
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="ms-2 bi bi-box-arrow-up-right" viewBox="0 0 16 16">
                                        <path fillRule="evenodd" d="M8.636 3.5a.5.5 0 0 0-.5-.5H1.5A1.5 1.5 0 0 0 0 4.5v10A1.5 1.5 0 0 0 1.5 16h10a1.5 1.5 0 0 0 1.5-1.5V7.864a.5.5 0 0 0-1 0V14.5a.5.5 0 0 1-.5.5h-10a.5.5 0 0 1-.5-.5v-10a.5.5 0 0 1 .5-.5h6.636a.5.5 0 0 0 .5-.5z" />
                                        <path fillRule="evenodd" d="M16 .5a.5.5 0 0 0-.5-.5h-5a.5.5 0 0 0 0 1h3.793L6.146 9.146a.5.5 0 1 0 .708.708L15 1.707V5.5a.5.5 0 0 0 1 0v-5z" />
                                    </svg></a>
                            </p>
                            <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                                <div className="dataTable-container table-responsive">
                                    <table className="border table table-hover table-borderless">
                                        <thead className=' border-bottom table-light'>
                                            <tr className='text-center'>
                                                <td scope="col" data-sortable="" style={{ width: '3%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">No</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '6%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Name</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '8%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Welcome message</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '9%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Created on</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '6%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Folder Path</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '9%', fontWeight: '500' }}><a href="#"

                                                    className=" text-dark text-decoration-none">Admin Number</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '9%', fontWeight: '500' }}><a href="#"

                                                    className=" text-dark text-decoration-none">Modified on</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '3%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Script</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '3%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Logo</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '1%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Redirect </a></td>
                                                <td scope="col" data-sortable="" style={{ width: '2%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Action </a></td>

                                            </tr>
                                        </thead>
                                        <tbody className=''>


                                            {isSuccess && GetChatboxData?.map((a) => {
                                                g++
                                                return (
                                                    <tr className='text-center border-bottom' key={[a.id]}>
                                                        <td scope="row">{g}</td>
                                                        <td>{a.chatbox_name}</td>
                                                        <td>{a.content}</td>
                                                        <td>{a.created_on}</td>
                                                        <td>{a.folderPath}</td>
                                                        <td>{a.adminPhNo}</td>
                                                        <td>{a.modifieded_on === null ? '-' : a.modifieded_on}</td>
                                                        <td>
                                                            <button onClick={() => { copyScripttag(a.id, a.folderPath, a.adminPhNo) }} className='border-0 text-dark btn btn-sm btn-outline-info'>copy</button>
                                                        </td>
                                                        <td>
                                                            <div className='d-flex justify-content-center align-items-center rounded-circle' id='logoPlaceHere'>
                                                                <img id='forLogo' src={`${process.env.REACT_APP_NODE_URL}/api${a.logo}`} className='rounded-circle' style={{
                                                                    height: '50px',
                                                                    width: '50px',
                                                                }} />

                                                            </div>
                                                        </td>
                                                        <td>{<button onClick={() => { editCreateChatBoxHandler(a) }} className='btn  btn-outline-secondary '>Edit</button>}</td>
                                                        <td>{<button onClick={() => { deleteChatbot(a) }} className='btn  btn-outline-danger '>Delete</button>}</td>
                                                    </tr>
                                                );
                                            })}


                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                        <div className="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                            container here
                        </div>

                    </div>
                </div>
            </div>
            {
                confirmScreenState === true && <ConfirmScreen screenFrom='container' deleteId={deleteId} setDeleteId={setDeleteId} setConfirmScreenState={setConfirmScreenState} />
            }
        </>
    )
}
export { ReturnfolderPath, ReturnadminPhNo };
export default Container