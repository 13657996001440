import Axios from 'axios'
import React, { useRef } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import AdminNav from '../AdminNav/AdminNav'
import ConfirmScreen from '../Container/ConfirmScreen'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getDomainListFromDB } from './getDomainListSlice'

import dp3 from '../images/dp3.webp'

import './adminPanel.css'
export default function AdminPanel(props) {
    const [getDomainName, setGetDomainName] = useState([])
    const [confirmScreenState, setConfirmScreenState] = useState(false)
    const [currentData, setCurrentData] = useState()
    const [userData, setUserData] = useState()
    const adminAsideRef = useRef()
    const mainContainerRef = useRef()
    const toggleBtnRef = useRef()
    const dispatch = useDispatch()
    const Navigate = useNavigate()
    const getDomainList = useSelector(state => state.getDomainList.domainList)


    useEffect(() => {
        const userData = localStorage.getItem('userData')
        if (userData && userData.length > 0) {

            setUserData(JSON.parse(userData))
        }
    }, [])
    useEffect(() => {
        dispatch(getDomainListFromDB())
    }, [])
    useEffect(() => {
        console.log('getDomainList', getDomainList)
        if (getDomainList.isSuccess) {
            if (getDomainList?.listData?.result.length > 0) {
                setGetDomainName(getDomainList?.listData.result)
            } else if (getDomainList?.listData?.isSuccess == false) {
                props.forCallAlert('Something is wrong')
            }
        }
    }, [getDomainList])


    function logOutHandler() {
        console.log('logOutHandler')
        Navigate("/")
        localStorage.setItem('isAdmin', "false");
        localStorage.setItem('isAuthenticate', "false");
        localStorage.setItem('userIdInLocal', "");
        localStorage.setItem('userData', "");
    }
    function toggleHandler() {
        console.log('toggleHandler called')
        console.log('root', document.getElementById('root').classList.contains('toggleSideBar'));
        console.log('adminAsideRef', adminAsideRef.current.classList.contains('closeAside'));

        // if (adminAsideRef.current.classList.contains('closeAside')) {
        //     adminAsideRef.current.classList.remove('closeAside')
        //     toggleBtnRef.current.classList.remove('closeAside')
        //     mainContainerRef.current.classList.remove('closeAside')
        // } else {
        //     adminAsideRef.current.classList.add('closeAside')
        //     toggleBtnRef.current.classList.add('closeAside')
        //     mainContainerRef.current.classList.add('closeAside')
        // }
        if (document.getElementById('root').classList.contains('toggleSideBar')) {
            document.getElementById('root').classList.remove('toggleSideBar')
        } else {
            document.getElementById('root').classList.add('toggleSideBar')
        }

    }
    function togglerHander(event, data) {
        setCurrentData({ data, event })
        if (confirmScreenState === true) {
            setConfirmScreenState(false)
        } else {
            setConfirmScreenState(true)
        }
    }
    return (

        <>
            <AdminNav></AdminNav>
            <aside ref={adminAsideRef} className='adminAside'>
                <main>
                    <ul>
                        <div>
                            <div className='mainProfile'>
                                <div className='imgContainer'>
                                    <img src={dp3} alt="dafaultImg" />

                                </div>
                                <div className='ps-2 d-flex flex-column '>
                                    <h6 className='text-uppercase'>{
                                        userData && userData?.firstName && `${userData.firstName} ${userData.lastName}`}</h6>
                                    <p>Admin</p>
                                </div>
                            </div>
                        </div>
                        <div className='pt-3'>
                            <h6>GENERAL</h6>
                            <li>
                                <a href="">Dashboard</a>
                            </li>
                            <li>
                                <a href="">Dashboard</a>
                            </li>
                            <li>
                                <a href="">Dashboard</a>
                            </li>
                        </div>
                        <div className='pt-3'>
                            <h6>MANAGEMENT</h6>
                            <li>
                                <a href="">Dashboard</a>
                            </li>
                            <li>
                                <a href="">Dashboard</a>
                            </li>
                            <li>
                                <a href="">Dashboard</a>
                            </li>
                        </div>
                    </ul>
                    <div>
                        <ul>
                            <li onClick={logOutHandler}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="me-2 bi bi-box-arrow-left" viewBox="0 0 16 16">
                                    <path fillRule="evenodd" d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
                                    <path fillRule="evenodd" d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                                </svg>

                                <a>Logout</a>


                            </li>
                        </ul>
                    </div>
                </main>
            </aside>
            <button ref={toggleBtnRef} onClick={toggleHandler} className='toggleBtn'>
                <main>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-caret-left" viewBox="0 0 16 16">
                        <path d="M10 12.796V3.204L4.519 8 10 12.796zm-.659.753-5.48-4.796a1 1 0 0 1 0-1.506l5.48-4.796A1 1 0 0 1 11 3.204v9.592a1 1 0 0 1-1.659.753z" />
                    </svg> */}
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-chevron-left" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z" />
                    </svg>
                </main>
            </button>
            <div ref={mainContainerRef} id="mainContainer" className="adminMain">
                <div>
                    <div className="container tab-content " id="myTabContent">
                        <div>
                            <h4 className="text-secondary mb-4">
                                User list
                            </h4>
                            <div className="dataTable-wrapper dataTable-loading no-footer sortable searchable fixed-columns">
                                <div className="dataTable-container table-responsive">
                                    <table className="border table table-hover table-borderless">
                                        <thead className=' border-bottom table-light'>
                                            <tr className='text-center'>
                                                <td scope="col" data-sortable="" style={{ width: '3%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">No</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '6%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">First Name</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '8%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Last Name</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '9%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Email Id </a></td>
                                                <td scope="col" data-sortable="" style={{ width: '9%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Domain Name</a></td>
                                                <td scope="col" data-sortable="" style={{ width: '9%', fontWeight: '500' }}><a href="#"
                                                    className=" text-dark text-decoration-none">Deactivate</a></td>
                                            </tr>
                                        </thead>
                                        <tbody className=''>
                                            {getDomainName?.map((a, index) => {
                                                return <tr className='text-center border-bottom' key={index}>
                                                    <td scope="row">{index + 1}</td>
                                                    <td>{a.first_name}</td>
                                                    <td>{a.last_name}</td>
                                                    <td>{a.email_id}</td>
                                                    <td>{a.domain_name}</td>
                                                    <td>
                                                        <div className=''>
                                                            <input checked={a.is_active === 1 ? false : true} onChange={(e) => { togglerHander(e, a) }} type="checkbox" />
                                                        </div>
                                                    </td>
                                                </tr>
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            {
                confirmScreenState === true && <ConfirmScreen currentData={currentData} setCurrentData={setCurrentData} screenFrom='adminPanel' setConfirmScreenState={setConfirmScreenState} />
            }
        </>
    )
}
